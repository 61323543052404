<template>
  <div>
    <header-image></header-image>
    <div class="container col-sm-8">
      <div v-if="successMsg" class="col-sm-12 success-msg mt-3">
        {{ successMsg }}
        <font-awesome-icon class="mt-1 fa-lg float-right" :icon="newIcon" />
      </div>
      <div class="row">
        <div class="col-12 text-justify">
          <div class="row justify-content-md-center"></div>

          <div v-if="!status" class="row justify-content-md-center">
            <div class="card fail col-sm-12 col-md-12 col-lg-11 mt-4">
              <h2>Please try again later</h2>
              <p>
                If the problem persists contact support at
                <a href="mailto:heretohelp@bp.com">heretohelp@bp.com</a>
              </p>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <div class="col-sm-12 col-md-12 mt-4 mb-5 text-center">
                <a
                  href="https://www.bppulse.co.uk/register"
                  class="btn btn-primary"
                  >Subscribe now</a
                >
              </div>
            </div>
          </div>
          <div v-else class="row justify-content-md-center">
            <div class="card fail col-sm-12 col-md-12 col-lg-11 mt-4">
              <h2>Thank you for choosing bp pulse</h2>
              <p>
                Access to over 7,000 charge points across the UK with an
                easy-to-use card or key fob
              </p>
              <p>
                We’re always here for you on our 24/7 friendly emergency
                helpline
              </p>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <div class="col-sm-12 col-md-12 mt-4 mb-5 text-center">
                <a href="https://www.bppulse.co.uk/" class="btn btn-primary"
                  >bp pulse</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-menu></footer-menu>
  </div>
</template>

<script>
import store from "../../store";
import router from "../../router";
import { mapState, mapGetters, mapMutations } from "vuex";

import HeaderImage from "../../components/HeaderImage";
import Journey from "../../components/Journey.vue";
import WorkplaceDeal from "../../components/WorkplaceDeal";
import WorkplacePolarDeal from "../../components/WorkplacePolarDeal";
import FooterMenu from "../../components/FooterMenu";

export default {
  name: "Success",
  metaInfo: {
    title: "Your Success",
    // override the parent template and just use the above title only
    meta: [{ name: "description", content: "Success page" }],
  },
  components: {
    HeaderImage,
    Journey,
    WorkplaceDeal,
    WorkplacePolarDeal,
    FooterMenu,
  },
  data: function () {
    return {
      errors: [],
      successMsg: "",
      logoURL: "",
      status: "",
      newIcon: "",
    };
  },
  mounted() {
    this.status = this.$route.query.status;
    if (this.status == "409") {
      this.successMsg = "You have already completed payment";
      this.newIcon = "check-circle";
    } else {
      this.successMsg = "Your card payment has failed";
      this.newIcon = "times-circle";
    }
  },
  methods: {},
};
</script>
<style scoped>
</style>
