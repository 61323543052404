<template>
  <div>
    <header-image></header-image>
    <div class="container col-sm-8">
      <h1 class="text-center mt-5 mb-4">Website Terms</h1>

      <div class="text-justify">
        <h3>Important information about the use of this website</h3>
        <p>
          Your use of this website (the website or this website) is governed by
          these terms and conditions. Please read them carefully.
        </p>
        <p>
          If you do not accept these terms and conditions, please do not
          continue to use the website. In particular, your attention is drawn to
          the sections below about “Our liability to you”, “Information and
          materials on this website” and our “Privacy policy”.
        </p>
        <p>
          We may occasionally change these terms and conditions by updating them
          on this webpage. You should check them from time to time to ensure you
          are happy with any changes. These terms and conditions were last
          updated on 25th April 2017.
        </p>
        <h3>About us</h3>
        <p>
          This website is owned and operated by bp Chargemaster (“bp
          Chargemaster”, “us, “we”). bp Chargemaster’s main address is at 500
          Capability Green, Luton, LU1 3LS, United Kingdom. More information
          about bp Chargemaster can be found at
          <a href="http://www.bpchargemaster.com">www.bpchargemaster.com</a>
        </p>
        <p>
          You can contact us by email at:
          <a href="mailto:sales@bpchargemaster.com">sales@bpchargemaster.com</a>
        </p>
        <h3>Information and materials on this website</h3>
        <p>
          Please note that the information contained on this website is given
          for general information and interest purposes only. While we try to
          ensure it is accurate and up-to-date, you should not rely on this
          information and we are not responsible for any action you may take
          based on this information. We recommend that you take further advice
          or seek further guidance before taking any action based on the
          information contained on this website. In particular, you are advised
          to obtain specialist advice from an appropriately qualified expert in
          relation to all technical aspects of charging your electric vehicle.
        </p>
        <p>
          The charge point information and maps on this website are partly
          obtained from third party sources and as a result we cannot guarantee
          that they are accurate, complete or up-to-date. Please also be aware
          that parking fees may apply at the charge point locations shown on
          this website. We cannot guarantee access to parking bays or charging
          points shown on the maps
        </p>
        <p>
          We have made this website available to you for your personal
          non-commercial use. We do not give you permission to do anything with
          this website except to browse its contents and use any other
          facilities we make available to you.
        </p>
        <p>
          This website and all the materials contained on it are protected by
          intellectual property rights, including copyright, and either belong
          to us or are licensed to us to use. Materials include, but are not
          limited to, the design, layout and appearance of this website as well
          as audio and video recording, graphics, text, images, stories,
          comments, documents, downloads, and other content on this website.
        </p>
        <p>
          You must not copy, redistribute, republish or otherwise make the
          materials on this website available to anyone else without our written
          consent, except for your own personal non-commercial use.
        </p>
        <p>
          You must take care not to do anything that could harm this website or
          the computer systems which host this website.
        </p>
        <h3>Our liability to you</h3>
        <p>
          For further information about your legal rights and how they may be
          affected by these terms and conditions including this section on our
          liability to you, we advise you to contact your local Citizens Advice
          Bureau.
        </p>
        <p>
          These terms and conditions do not exclude or limit our liability (if
          any) to you for any matter which it would be illegal for us to exclude
          or limit or to attempt to exclude or limit our liability.
        </p>
        <p>
          We are not responsible to you for any losses you may incur which were
          not a foreseeable or obvious consequence of us breaching these terms
          and conditions – for example, if you and we could not have anticipated
          those losses before or when you access this website or it was not
          obvious that those losses would result.
        </p>
        <p>
          While we try to ensure that this website is functioning correctly,
          this may not always be achievable. We do not guarantee that this
          website will be available all the time or at any specific time.
        </p>
        <p>
          We take steps to ensure that this website is free from computer
          viruses and other harmful computer programs. However, we cannot
          guarantee this due to the nature of the Internet. You are advised to
          use appropriate firewall and anti-virus software to protect your
          computer and data.
        </p>
        <p>
          We do not guarantee that this website will be compatible with all or
          any hardware and software which you may use.
        </p>
        <p>
          We are not responsible to you for losses which you suffer due to any
          event beyond our reasonable control.
        </p>
        <p>
          Our liability to you shall not in any circumstances include any
          business losses that you may incur, including but not limited to lost
          business data, lost profits or business interruption.
        </p>
        <h3>Links</h3>
        <p>
          This website may contain links to other websites over which we have no
          control. Such links are supplied solely for the convenience of users.
          We are not responsible for the contents or reliability of the linked
          websites and do not necessarily endorse the views expressed within
          them or the organisation or persons providing them in any way. We do
          not guarantee that these links will work all of the time and we have
          no control over the availability of the linked pages.
        </p>
        <p>
          Providers of other websites may place text-based links to pages on the
          website without seeking our permission. However such links must not
          open this website pages into frames within another website. Pages from
          the website must be loaded into the user’s entire browser window.
        </p>
        <p>
          The use of bp pulse or bp Chargemaster logo or the logos of any
          associated or subsidiary organisations must not be used for
          promotional or linking purposes unless prior approval in writing has
          been given. Please contact us for further details on obtaining such
          approval.
        </p>
        <h3>Other information of which you need to be aware</h3>
        <p>
          We may withdraw your permission to use this website or use any
          facilities on this website if in our view you have seriously failed to
          comply with any of these terms and conditions.
        </p>
        <p>
          We may modify this website at any time, for instance to comply with
          changes in the law or to add new features. We will try as far as
          possible to ensure that any modifications do not reduce the quality of
          the website.
        </p>
        <p>
          We reserve the right to withdraw, suspend or discontinue any
          functionality or feature on this website at any time.
        </p>
        <p>
          These terms and conditions and all communications between us regarding
          them and/or the website will be conducted in the English language.
        </p>
        <p>
          Neither of us intend that these terms and conditions will be
          enforceable by anyone except us and you by virtue of the Contracts
          (Rights of Third Parties) Act 1999.
        </p>
        <p>
          English law applies to these terms and conditions and all matters
          relating to your use of this website.
        </p>
        <p>
          If any dispute arises between us in relation to these terms and
          conditions or your use of this website and you want to take court
          proceedings, you must do so in the English courts.
        </p>
      </div>
    </div>
    <footer-menu></footer-menu>
  </div>
</template>

<script>
import store from "../store";
import router from "../router";
import { mapState, mapGetters, mapMutations } from "vuex";

import HeaderImage from "../components/HeaderImage";
import FooterMenu from "../components/FooterMenu";

export default {
  name: "Terms",
  metaInfo: {
    title: "Your Terms",
    // override the parent template and just use the above title only
    meta: [{ name: "description", content: "Terms page" }],
  },
  components: {
    HeaderImage,
    FooterMenu,
  },
  data: function () {
    return {};
  },
  methods: {},
};
</script>

