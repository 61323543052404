<template>
  <div>
    <header-image></header-image>
    <div class="container col-sm-8">
      <h1 class="mt-4 mb-4">Reset Password</h1>
      <div v-if="submitSuccess">
        <div class="row">
          <div class="success">
            <p>
              Your password has been successfully reset.
            </p>
            <div class="col-sm-12 col-md-12 mt-4 text-right">
              <router-link
                  to="/"
                  class="btn btn-secondary mt-1">
                Return to Login
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="invalidToken">
          <div class="row">
            <div class="col-sm-12 col-md-12">
              The link you have clicked is invalid or may have expired. <br/><br/>
              Please either return to Login or request a new reset password link using the buttons below.
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 mt-4 text-right">
              <router-link
                  to="/"
                  class="btn btn-secondary mt-1">
                Return to Login
              </router-link>
            </div>
            <div class="col-sm-12 col-md-12 mt-4 text-right">
              <router-link
                  to="/forgot-password"
                  class="btn btn-secondary mt-1">
                Password Reset
              </router-link>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <p>Your password must contain all of the following:
              <ul>
                <li>a minimum of 8 characters</li>
                <li>a number</li>
                <li>a lowercase letter</li>
                <li>an uppercase letter</li>
                <li>a special character (e.g. ! * ^ %)</li>
              </ul></p>
              <div v-if="errors.length" class="errors">
                <ul>
                  <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                </ul>
              </div>
              <label>Email Address</label>
              <input
                  type="text"
                  id="email"
                  class="form-control"
                  v-model="userEmail"
              />
              <label>Password</label>
              <input
                  type="password"
                  id="password"
                  class="form-control"
                  v-model="userPassword"
              />
              <label>Confirm Password</label>
              <input
                  type="password"
                  id="password-confirmation"
                  class="form-control"
                  v-model="userPasswordConfirmation"
              />
            </div>

            <div class="col-sm-12 col-md-12 mt-4 text-right">
              <div class="btn btn-primary" @click="resetPassword()">Reset Password</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../store";
import router from "../router";
import { mapState, mapGetters, mapMutations } from "vuex";

import HeaderImage from "../components/HeaderImage";

export default {
  name: "ResetPassword",
  metaInfo: {
    title: "Reset Password",
    // override the parent template and just use the above title only
    meta: [{ name: "description", content: "Reset password" }],
  },
  components: {
    HeaderImage,
  },
  data: function () {
    return {
      errors: [],
      submitSuccess: false,
      invalidToken: false,
      userEmail: "",
      userPassword: "",
      userPasswordConfirmation: "",
    };
  },
  computed: {
  },
  mounted() {
    this.$http
        .post(
            this.$store.state.appURL + "validate-token",
            {
              token: this.$route.query.token,
            }
        )
        .then((response) => {

        })
        .catch((e) => {
          this.invalidToken = true;
        });
  },
  methods: {
    async resetPassword() {
      this.invite = this.$store.state.invite;
      this.errors = [];
      if (!this.userEmail) {
        this.errors.push("Email address is required.");
      } else if (!this.validEmail(this.userEmail)) {
        this.errors.push("Valid email required.");
      }
      if (!this.userPassword || !this.userPasswordConfirmation) {
        this.errors.push("Password and password confirmation are required.");
      } else if (this.userPassword !== this.userPasswordConfirmation) {
        this.errors.push("Password and password confirmation do not match.");
      } else if (!this.validPassword(this.userPassword)) {
        this.errors.push("Password does not meet strength requirements.");
      }

      if (!this.errors.length) {
        this.$http
          .post(
            this.$store.state.appURL + "reset-password",
            {
              user_email: this.userEmail,
              password: this.userPassword,
              password_confirmation: this.userPasswordConfirmation,
              token: this.$route.query.token,
            }
          )
          .then((response) => {
            this.submitSuccess = true;
          })
          .catch((e) => {
            this.errors.push(
              "Password reset failed, please check you are using the correct email address and try again"
            );
          });
      }
    },
    validEmail(email) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validPassword(password) {
      let re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s])).{8,}$/;
      return re.test(password);
    },
  },
};
</script>

