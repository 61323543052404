<template>
  <div>
    <header-image></header-image>
    <div class="container col-sm-8">
      <h1 class="text-center mt-5 mb-4">Contact Details</h1>

      <div class="text-justify">
        <h4>bp Chargemaster</h4>
        <p>
          Linford Wood,
          <br />Milton Keynes, <br />Buckinghamshire, <br />MK14 6LB
        </p>
        <p>Tel: 0330 016 5126</p>
        <p>Our Customer Service Team is available 24/7</p>

        <p>
          Email:
          <a href="mailto:heretohelp@bp.com">heretohelp@bp.com</a>
        </p>
        <contact-map />
      </div>
    </div>
    <footer-menu></footer-menu>
  </div>
</template>

<script>
import store from "../store";
import router from "../router";
import { mapState, mapGetters, mapMutations } from "vuex";

import HeaderImage from "../components/HeaderImage";
import FooterMenu from "../components/FooterMenu";
import ContactMap from "../components/ContactMap";

export default {
  name: "Contact",
  metaInfo: {
    title: "Your Contact",
    // override the parent template and just use the above title only
    meta: [{ name: "description", content: "Contact page" }],
  },
  components: {
    HeaderImage,
    FooterMenu,
    ContactMap,
  },
  data: function () {
    return {};
  },
  mounted() {},

  methods: {},
};
</script>
<style scoped>
</style>

