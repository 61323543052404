<template>
  <div>
    <header-image :logo="logoURL"></header-image>
    <div class="container col-sm-8">
      <h1 class="text-center mt-5 mb-4">
        You're invited to bp pulse Workplace at
        <strong>{{ invite.company.name }}</strong>
      </h1>

      <h2 class="text-center mt-4 mb-4">Verify your bp pulse Membership</h2>
      <p>Enter your email and Password</p>
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <label>Email</label>
          <input
            type="email"
            id="email"
            class="form-control"
            v-model="login.email"
          />
          <label>Password</label>
          <input
            type="password"
            id="password"
            class="form-control"
            v-model="login.password"
          />
          <div v-if="successMsg" class="col-sm-12 success-msg mt-3">
            {{ successMsg }}
            <font-awesome-icon
              class="mt-1 fa-lg float-right"
              icon="times-circle"
            />
          </div>
          <div v-if="successMsg && !flags.verified_polar_member">
            <p>
              Please contact us to re-issue your bp pulse credentials or reset
              your password bellow
            </p>
          </div>
        </div>

        <div class="col-sm-12 col-md-12 mt-4 text-right">
          <div class="btn btn-primary" @click="logIn()">Verify</div>
        </div>

        <!-- error section -->
        <div class="errors">
          <p v-if="errors.length">
            <em>Please correct the following error(s):</em>
          </p>
          <ul>
            <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../../store";
import router from "../../router";
import { mapState, mapGetters, mapMutations } from "vuex";

import HeaderImage from "../../components/HeaderImage";

export default {
  name: "VerifyUser",
  metaInfo: {
    title: "Verify User",
    // override the parent template and just use the above title only
    meta: [{ name: "description", content: "Test Log In page" }],
  },
  components: {
    HeaderImage,
  },
  data: function () {
    return {
      errors: [],
      successMsg: "",
      logoURL: "",
      userId: null,
      invite: {
        id: "",
        company_id: "",
        code: "",
        first_name: "",
        last_name: "",
        email: "",
        meta_data: {},
        created_at: "",
        updated_at: "",
        company: {
          id: "",
          logo: null,
          name: "",
          manager_first: "",
          manager_last: "",
          address_one: "",
          address_two: "",
          post_code: "",
          country: "",
          created_at: "",
          updated_at: "",
        },
      },
      login: {
        email: "",
        password: "",
      },
      sessionId: "",
    };
  },
  computed: {
    authenticated: function () {
      return this.$store.state.authenticated;
    },
  },
  mounted() {
    // get code from URL
    this.invite.code = this.$route.query.code;

    // get screen data using code
    this.$http
      .get(this.$store.state.appURL + "invite/" + this.invite.code)
      .then((response) => {
        this.invite = response.data;

        if (this.invite.company.logo) {
          this.logoURL = this.$store.state.appURL + this.invite.company.logo;
        }
        store.commit("updateInvite", response.data);
      })
      .catch((error) => {
        for (let [key, value] of Object.entries(error.response.data)) {
          this.errors.push(value[0]);
        }
      });
  },
  methods: {
    async logIn() {
      this.errors = [];
      this.login.code =  this.invite.code;
      // test if user is a Polar Member
      this.$http
        .post(this.$store.state.appURL + "verify-polar", this.login)
        .then((response) => {
          router.push({
            path: "/success",
            query: { code: this.invite.code },
          });
          //update user as Polar Member
          this.userId = response.data.user_id;
          this.invite.meta_data.verified_polar_member = true;
          this.$http
            .patch(
              this.$store.state.appURL + "invite/" + this.invite.code,
              {
                meta_data: this.invite.meta_data,
              }
            )
            .then((response) => {
              store.commit("updateInvite", response.data);
              router.push({
                path: "/success",
                query: { code: this.invite.code },
              });
            })
            .catch((error) => {
              for (let [key, value] of Object.entries(error.response.data)) {
                this.errors.push(value[0]);
              }
            });
        })

        .catch((error) => {
          this.errors.push(
            "Verification failed, please check the details you entered"
          );
        });
    },

    setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },

    validEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>