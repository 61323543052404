<template>
  <div>
    <header-image :logo="logoURL" :success="true"></header-image>
    <div class="container col-sm-8">
      <h1 class="text-center mt-5 mb-4">
        bp pulse Workplace at
        <strong>{{ invite.company.name }}</strong>
      </h1>
      <div class="row">
        <div class="col-2">
          <journey
            v-if="!invite.meta_data.verified_polar_member"
            :next-step="4"
          ></journey>
          <div v-if="invite.meta_data.verified_polar_member">
            <h1 class="step-polar">
              <font-awesome-icon icon="check" />
            </h1>
          </div>
        </div>
        <!-- What RFID -->
        <div class="col-10 text-justify">
          <h1>Success!</h1>
          <h5 v-if="invite.meta_data.verified_polar_member">
            Your bp pulse Membership has been verified
          </h5>
          <h5>
            We've received your payment details, and you're all signed up.
          </h5>

          <div class="row justify-content-md-center">
            <div
              v-if="invite.meta_data.workplace_only"
              class="card deal col-sm-12 col-md-12 col-lg-12"
            >
              <workplace-deal :work-price="workplace_price"></workplace-deal>
            </div>
            <div
              v-if="!invite.meta_data.workplace_only"
              class="card deal col-sm-12 col-md-12 col-lg-12"
            >
              <workplace-polar-deal
                :work-price="workplace_price"
                :polar-price="this.$store.state.company_polar_price"
              ></workplace-polar-deal>
            </div>
          </div>

          <div class="row justify-content-md-center">
            <p class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <router-link class="float-right" to="/terms"
                >Cancel subscription</router-link
              >
            </p>
          </div>
          <div
            v-if="
              invite.meta_data.workplace_only &&
              !invite.meta_data.verified_polar_member
            "
            class="row justify-content-md-center"
          >
            <div class="col-sm-12 col-md-12 col-lg-6">
              <h5>Are you sure you don't want bp pulse membership?</h5>
              <p>
                If you need to charge outside of your workplace, you can charge
                free on the majority of the UK's largest public charging network
                for just £{{ this.$store.state.company_polar_price }} per month.
                The rest charge from just 28p per kWh. For full T&amp;Cs visit
                <a href="https://bpchargemaster.com/polar/terms-and-conditions/"
                  >bp pulse Terms and Conditions</a
                >
              </p>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <h5>bp pulse membership?</h5>
              <h1>
                <strong>£{{ this.$store.state.company_polar_price }}</strong
                >/month
              </h1>
              <div class="col-sm-12 col-md-12 mt-4 mb-5 text-center">
                <a
                  href="https://www.bppulse.co.uk/register"
                  class="btn btn-primary"
                  >Subscribe now</a
                >
              </div>
            </div>
          </div>

          <!-- error section -->
          <div class="errors">
            <p v-if="errors.length">
              <em>Please correct the following error(s):</em>
            </p>
            <ul>
              <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <footer-menu></footer-menu>
  </div>
</template>

<script>
import store from "../../store";
import router from "../../router";
import { mapState, mapGetters, mapMutations } from "vuex";

import HeaderImage from "../../components/HeaderImage";
import Journey from "../../components/Journey.vue";
import WorkplaceDeal from "../../components/WorkplaceDeal";
import WorkplacePolarDeal from "../../components/WorkplacePolarDeal";
import FooterMenu from "../../components/FooterMenu";

export default {
  name: "Success",
  metaInfo: {
    title: "Your Success",
    // override the parent template and just use the above title only
    meta: [{ name: "description", content: "Success page" }],
  },
  components: {
    HeaderImage,
    Journey,
    WorkplaceDeal,
    WorkplacePolarDeal,
    FooterMenu,
  },
  data: function () {
    return {
      errors: [],
      successMsg: "",
      logoURL: "",
      workplace_price: "",

      invite: {
        id: "",
        company_id: "",
        code: "",
        first_name: "",
        last_name: "",
        email: "",
        meta_data: {},
        created_at: "",
        updated_at: "",
        company: {
          id: "",
          logo: null,
          name: "",
          manager_first: "",
          manager_last: "",
          address_one: "",
          address_two: "",
          post_code: "",
          country: "",
          created_at: "",
          updated_at: "",
        },
      },
      revenue_rates: {
        owner_id: "",
        revenue_rate_energy_charge: "",
        revenue_rate_plan: 21,
      },
    };
  },
  mounted() {
    // get code from URL
    this.invite.code = this.$route.query.code;

    // get screen data using code
    this.$http
      .get(this.$store.state.appURL + "invite/" + this.invite.code)
      .then((response) => {
        this.invite = response.data;

        if (this.invite.company.logo) {
          this.logoURL = this.$store.state.appURL + this.invite.company.logo;
        }
        store.commit("updateInvite", response.data);

        // this.$http
        //   .get(
        //     this.$store.state.appURL +
        //       "company/" +
        //       this.invite.company.id +
        //       "?code=" +
        //       this.invite.code
        //   )
        //   .then((response) => {
        //     this.invite.company = response.data;

        //     this.invite.company.owner_id = this.invite.company.owner_id;
        this.revenue_rates.owner_id = this.invite.company.owner_id;

        this.$http
          .get(
            this.$store.state.appURL +
              "revenue-show?owner_id=" +
              this.revenue_rates.owner_id +
              "&revenue_rate_plan=" +
              this.revenue_rates.revenue_rate_plan
          )

          .then((response) => {
            this.revenue_rates = response.data;
            this.workplace_price = Number(
              this.revenue_rates.revenue_rate_energy_charge
            ).toFixed(2);
          })
          .catch((error) => {
            for (let [key, value] of Object.entries(error.response.data)) {
              this.errors.push(value[0]);
            }
          });
        // })
        // .catch((error) => {
        //   for (let [key, value] of Object.entries(error.response.data)) {
        //     this.errors.push(value[0]);
        //   }
        // });
      })
      .catch((error) => {
        for (let [key, value] of Object.entries(error.response.data)) {
          this.errors.push(value[0]);
        }
      });
  },
  methods: {
    goCardless() {
      router.push("your-details");
    },
  },
};
</script>
<style scoped>
.step-polar {
  font-size: 1.5rem;
  width: 45px;
  height: 45px;
  margin-left: 30%;
  padding: 0 10px;
  color: #00aeef;
  border: #00aeef solid 2px;
  border-radius: 50%;
  line-height: 46px;
}
</style>
