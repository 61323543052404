<template>
  <div>
    <header-image :logo="logoURL"></header-image>
    <div class="container col-sm-8">
      <h1 class="text-center mt-5 mb-4">
        You're invited to bp pulse Workplace at
        <strong>{{ invite.company.name }}</strong>
      </h1>

      <div class="text-justify">
        <p>
          Welcome
          <strong class="mr-4">{{ invite.first_name }}!</strong>
        </p>
        <p>
          <strong>{{ invite.company.name }}</strong> has invited you to join bp
          pulse Workplace. We just need a few details from you if you would like
          to join. Should only take about 5 minutes of your time.
        </p>
        <div class="row justify-content-md-center">
          <div v-if="this.show_options"
            @click="pickDeal()"
            :class="{ deal: invite.meta_data.workplace_only }"
            class="card col-sm-12 col-md-12 col-lg-5"
          >
            <h1 class="dot">
              <font-awesome-icon :icon="['far', 'dot-circle']" />
            </h1>
            <h1 class="circle">
              <font-awesome-icon :icon="['far', 'circle']" />
            </h1>
            <p>Join bp pulse Workplace</p>
            <workplace-deal :work-price="workplace_price"></workplace-deal>
          </div>

          <div v-if="this.show_options"
            @click="pickDeal()"
            :class="{ deal: !invite.meta_data.workplace_only }"
            class="card col-sm-12 col-md-12 col-lg-5"
          >
            <h1 class="dot">
              <font-awesome-icon :icon="['far', 'dot-circle']" />
            </h1>
            <h1 class="circle">
              <font-awesome-icon :icon="['far', 'circle']" />
            </h1>
            <p>Join bp pulse Workplace &amp; bp pulse</p>
            <workplace-polar-deal
              :work-price="workplace_price"
              :polar-price="this.$store.state.company_polar_price"
            ></workplace-polar-deal>
          </div>
          <div class="col-sm-12 col-md-12 mt-4 mb-5 text-center">
            <div v-if="this.show_options" class="btn btn-primary" @click="register()">Register now</div>
            <div v-else>
              <b-spinner class="cmblue" label="Loading..."></b-spinner>
            </div>
          </div>
        </div>

        <!-- error section -->
        <div class="errors">
          <p v-if="errors.length">
            <em>Please correct the following error(s):</em>
          </p>
          <ul>
            <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
          </ul>
        </div>
      </div>
    </div>
    <footer-menu></footer-menu>
  </div>
</template>

<script>
import store from "../../store";
import router from "../../router";
import { mapState, mapGetters, mapMutations } from "vuex";

import HeaderImage from "../../components/HeaderImage";
import WorkplaceDeal from "../../components/WorkplaceDeal";
import WorkplacePolarDeal from "../../components/WorkplacePolarDeal";
import FooterMenu from "../../components/FooterMenu";

export default {
  name: "Invite",
  metaInfo: {
    title: "Your Invite",
    // override the parent template and just use the above title only
    meta: [{ name: "description", content: "Invite page" }],
  },
  components: {
    HeaderImage,
    WorkplaceDeal,
    WorkplacePolarDeal,
    FooterMenu,
  },
  data: function () {
    return {
      errors: [],
      logoURL: "",
      workplace_price: "",
      show_options: false,

      invite: {
        owner_id: null,
        id: "",
        company_id: "",
        code: "",
        first_name: "",
        last_name: "",
        email: "",
        meta_data: {},
        created_at: "",
        updated_at: "",
        company: {
          owner_id: null,
        },
      },
      revenue_rates: {
        owner_id: "",
        revenue_rate_energy_charge: "",
        revenue_rate_plan: 21,
      },
    };
  },
  mounted() {
    // get code from URL
    this.invite.code = this.$route.query.code;

    // get screen data using code
    this.$http
      .get(this.$store.state.appURL + "invite/" + this.invite.code)
      .then((response) => {
        this.invite = response.data;
        this.show_options = true;
        if (this.invite.company.logo) {
          this.logoURL = this.$store.state.appURL + this.invite.company.logo;
        }
        //store.commit("updateInvite", response.data);
        this.revenue_rates.owner_id = this.invite.company.owner_id;
        this.$http
          .get(
            this.$store.state.appURL +
              "revenue-show?owner_id=" +
              this.revenue_rates.owner_id +
              "&revenue_rate_plan=" +
              this.revenue_rates.revenue_rate_plan
          )
          .then((response) => {
            this.revenue_rates = response.data;
            this.workplace_price = Number(
              this.revenue_rates.revenue_rate_energy_charge
            ).toFixed(2);
          })
          .catch((error) => {
            for (let [key, value] of Object.entries(error.response.data)) {
              this.errors.push(value[0]);
            }
          });
      })
      .catch((error) => {
        for (let [key, value] of Object.entries(error.response.data)) {
          this.errors.push(value[0]);
        }
      });
  },
  methods: {
    pickDeal() {
      this.invite.meta_data.workplace_only = !this.invite.meta_data
        .workplace_only;

      //update workplace only
      this.$http
        .patch(
          this.$store.state.appURL + "invite/" + this.invite.code,
          {
            meta_data: this.invite.meta_data,
          }
        )
        .then((response) => {
          store.commit("updateInvite", response.data);
        })
        .catch((error) => {
          for (let [key, value] of Object.entries(error.response.data)) {
            this.errors.push(value[0]);
          }
        });
    },
    register() {
      router.push({ path: "your-details", query: { code: this.invite.code } });
    },
  },
};
</script>

