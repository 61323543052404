import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appURL: process.env.VUE_APP_WORKPLACE_API_GATEWAY,
    cv3URL: process.env.VUE_APP_CV3_BASE_URL,
    company_polar_price: process.env.VUE_APP_WORKPLACE_POLAR_PRICE,
    //company_polar_price: '7.77',
    api_token: '',
    api_key: '',

    authenticated: false,
    invite:{
      id: null,
      company_id: null,
      owner_id: null,
      code: '',
      first_name: '',
      last_name: '',
      email: '',
      meta_data: {
        user_id: '',
        rfid_key: '',
        rfid_type_fob: true, // true key fob - only / false credit card size
        workplace_only: true, // true workplace - only / false workplace & bp pulse
        deliver_workplace: true, // true workplace  / false home
        verified_polar_member: false // true polar mwmber  / false non member
      },
      created_at: '',
      updated_at: '',
      company: {
          id: null,
          logo: null,
          price: null,
          name: '',
          manager_first: '',
          manager_last: '',
          address_one: '',
          address_two: '',
          post_code: '',
          country: '',
          created_at: '',
          updated_at: ''
      }
    },
    your_details: {
      user_email: '',
      user_password: '',
      user_firstname: '',
      user_lastname: '',
      user_vehicle: '',
      vehicle_id: ''
    },
    user: {
      first_name: '',
      last_name: '',
      address_one: '',
      address_two: '',
      post_code: '',
      country: '',
      email:'',
      company_id: '',
      rfid_type_fob: null, 
      workplace_only: null, 
      deliver_workplace: null, 
      verified_polar_member: null 
    }
  },
  mutations: {
     updateAuthenticated(state, data) {
      state.authenticated = data
    },
    updateApiToken(state, data) {
      state.api_token = data
    },
    updateApiKey(state, data) {
      state.api_key = data
    },
    updateInvite(state, data) {
      // mutate state
      state.invite = data
    },
    updateYourDetails(state, data) {
      // mutate state
      state.your_details = data
    },
    updateUser(state, data) {
      // mutate state
      state.user = data
    }
  },
  getters: {
    authenticated: state => {
      return state.authenticated;
    },
    api_token: state => {
      return state.api_token;
    },
    api_key: state => {
      return state.api_key;
    },
    invite: state => {
      return state.invite;
    },
    your_details: state => {
      return state.your_details;
    },
    user: state => {
      return state.user;
    }
  },
  actions: {
    fetchAuthenticated({ commit }) {
      commit('updateAuthenticated');
    },
    fetchApiToken({ commit }) {
      commit('updateApiToken');
    },
    fetchApiKey({ commit }) {
      commit('updateApiKey');
    },
    fetchInvite({ commit }) {
      commit('updateInvite');
    },
    fetchYourDetails({ commit }) {
      commit('updateYourDetails');
    },
    fetchUser({ commit }) {
      commit('updateUser');
    }
  }
})
