<template>
  <!-- SUB NAV -->
  <div class="journey row">
    <div :class="{livefirst: nextStep > 0}" ><h1>1</h1><span class="head">Your Details</span></div>
    <div class="step" :class="{live: nextStep > 1}"><h1>2</h1><span class="head">RFID Card</span></div>
    <div class="step" :class="{live: nextStep > 2}"><h1>3</h1><span class="head">Payment</span></div>
    <div class="step" :class="{live: nextStep > 3}"><h1><font-awesome-icon icon="check" /></h1><span class="head">Done!</span></div>
  </div>
</template>
<script>
export default {
  data: function() {
    return {};
  },
   props: {
    nextStep: Number
   }
};
</script>
<style scoped>
.journey {
    height: 530px;
    width: 50px;
    padding-left: 5px;
    padding-bottom: 10px;
    overflow: hidden;
}
.journey .head {
    position: relative;
    font-size: 10px;
    display: none;
}


.journey h1 {
    padding: 0;
    text-align: center;
    width: 30px;
    height: 30px;
    color: #ccc;
    border: #ccc solid 2px;
    background-color: #fff;
    border-radius: 50%;
    font-weight: 900;
    margin: 100px 10px 0 10px;
    line-height: 26px;
    font-size: 15px;
    float: left;
}
.journey div {
    position: relative;
    width: 100%;
    display: block;
    overflow: hidden;
    top: -40px;
}
.journey .step {
    background-image: url("../img/journey-track.svg");
    background-repeat: no-repeat;    
}
.journey .live h1, .journey .livefirst h1  {
    color: #000096;
    background-color: #9BFF00;
    border: #000096  solid 2px;
}
.journey .live {
    background-image: url("../img/journey-track-live.svg");
    background-repeat: no-repeat;
    overflow: hidden;    
}
/* X X X X DESKTOP  X X X  */

@media (min-width: 993px) {
  .journey {
    width: 120px;
    padding-left: 0px;
  }
  .journey h1 {
    padding: 0;
    width: 40px;
    height: 40px;
    margin: 90px 2px 0 5px;
    line-height: 36px;
    font-size: 20px;
  }
  .journey .head {
    position: relative;
    top: 104px;
    display: block;
  }
}
</style>