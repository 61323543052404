<template>
  <div>
    <div class="ribbon">
      <div class="banner-box container col-sm-8">
        <img
          :src="require('../../src/img/bg-register.jpg')"
          alt="bp pulse App Screens"
        />

        <div class="banner-text col-sm-6 col-md-6">
          <div class="text-white">
            <h3>Activate Your Card</h3>
            <p class="lead text-white">
              If you have received an inactive card from one of our partners,
              please fill in your details below.
              <br />If you signed up online and received your card in the post,
              please go to the
              <router-link to="/">Login</router-link>
              page.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container col-sm-8">
      <div class="mt-4 mb-4 col-lg-12 text-center col-xs-12">
        <h3>Activate Your Card</h3>
      </div>
      <div class="row justify-content-xl-center">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-10">
          <p>
            Enter the bp pulse Card ID number (found on the bottom right of your
            card)
          </p>
          <p>
            <input
              type="text"
              class="form-control"
              id="card_number"
              v-model="activate.card_number"
              placeholder="Card Number"
            />
          </p>
          <div class="bg-grey">
            <label>Start Date</label>
            <p>
              Please let us know when you would like your membership to start.
              We need to allow a minimum of four days before your card will
              activate and your membership will commence
            </p>

            <p><datepicker v-model="activate.start_date"></datepicker></p>
          </div>

          <p>
            Please enter your referral code (found on the leaflet accompanying
            your bp pulse card)
          </p>
          <p>
            <input
              type="text"
              class="form-control"
              id="referral"
              v-model="activate.referal_number"
              placeholder="Referral Number"
            />
          </p>
          <!-- BUTTONS -->
          <div class="text-white mt-4">
            <p>
              <router-link to="/activate" class="btn btn-secondary"
                >Activate Card</router-link
              >
            </p>
          </div>
          <p>
            *You will be charged for any electricity costs incurred, even during
            your free period of membership. The majority of our sites are free
            to use.
          </p>
          <p>
            For the others, you will be charged from just 28p per kWh. To see
            which charge points have electricity costs, please see our
            <a href="https://network.bppulse.co.uk/live-map/">Live Map</a>.
          </p>
        </div>
      </div>
    </div>
    <footer-menu></footer-menu>
  </div>
</template>

<script>
import store from "../store";
import router from "../router";
import { mapState, mapGetters, mapMutations } from "vuex";

import HeaderImage from "../components/HeaderImage";
import FooterMenu from "../components/FooterMenu";
import Datepicker from "vuejs-datepicker";

export default {
  name: "ActivateCard",
  metaInfo: {
    title: "Activate Card",
    // override the parent template and just use the above title only
    meta: [{ name: "description", content: "Activate Card page" }],
  },
  components: {
    HeaderImage,
    FooterMenu,
    Datepicker,
  },
  data: function () {
    return {
      activate: {
        card_number: "",
        start_date: "",
        referal_number: "",
      },
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.icon {
  height: 175px;
}
.bg-grey {
  background-color: #ddd;
  padding: 10px 20px;
  height: 170px;
  margin-bottom: 15px;
}
.banner {
  border: 2px solid #00aeef;
  color: #00aeef;
  border-radius: 20px;
  text-align: center;
  padding: 10px 15px;
  margin-bottom: 15px;
}

.banner h1 {
  color: #00aeef;
}
.banner-text {
  position: absolute;
  top: 15px;
}

.cmblue {
  color: #03aeef;
}
.banner-box {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  height: 300px;
}
.banner-box img {
  width: 100%;
  height: auto;
}
.banner-box .col-lg-5 {
  max-width: 100%;
}
.ribbon {
  background-color: #050;
  width: 100%;
}
/* X X X X MOBILE  PORTRAIT X X X  */

@media only screen and (max-width: 768px) and (orientation: portrait) {
}

/* X X X X Landscape TABLET  X X X  */

@media (min-width: 1090px) {
  .banner-text {
    position: absolute;
    top: 0px;
    margin-top: 2%;
  }
}
/* X X X X DESKTOP  X X X  */

@media (min-width: 1500px) {
}
</style>
