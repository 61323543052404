<template>
  <div>
    <header-image :logo="logoURL"></header-image>
    <div class="container col-sm-8">
      <h1 class="col-sm-12 col-md-12 text-center mt-5 mb-4">
        bp pulse Workplace at
        <strong>{{ invite.company.name }}</strong>
      </h1>

      <div class="row">
        <div class="col-2">
          <journey :next-step="1"></journey>
        </div>

        <div class="col-10 text-justify">
          <p>Setting up your account</p>
          <div class="row">
            <div class="col-md-6">
              <label>First Name</label>
              <input
                type="text"
                class="form-control"
                v-model="your_details.user_firstname"
              />
            </div>
            <div class="col-md-6">
              <label>Last Name</label>
              <input
                type="text"
                class="form-control"
                v-model="your_details.user_lastname"
              />
            </div>
          </div>
          <label>Password</label>
          <input
            type="password"
            class="form-control"
            v-model="your_details.user_password"
          />
          <label>Vehicle Make</label>
          <input
            type="text"
            class="form-control"
            v-model="your_details.user_vehicle"
          />
          <label>Vehicle Registration</label>
          <input
            type="text"
            class="form-control"
            v-model="your_details.vehicle_id"
          />

          <div class="col-sm-12 col-md-12 mt-4 mb-5 text-center">
            <div class="btn btn-primary" @click="addUser()">Next</div>
          </div>
          <!-- error section -->
          <div class="errors">
            <p v-if="errors.length">
              <em>Please correct the following error(s):</em>
            </p>
            <ul>
              <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <footer-menu></footer-menu>
  </div>
</template>

<script>
import store from "../../store";
import router from "../../router";
import { mapState, mapGetters, mapMutations } from "vuex";

import HeaderImage from "../../components/HeaderImage";
import Journey from "../../components/Journey.vue";
import FooterMenu from "../../components/FooterMenu";

export default {
  name: "YourDeatils",
  metaInfo: {
    title: "Your Details",
    // override the parent template and just use the above title only
    meta: [{ name: "description", content: "Your Details page" }],
  },
  components: {
    HeaderImage,
    Journey,
    FooterMenu,
  },
  data: function () {
    return {
      errors: [],
      successMsg: "",
      logoURL: "",
      invite: {
        id: "",
        company_id: "",
        code: "",
        first_name: "",
        last_name: "",
        email: "",
        meta_data: {
          user_id: "",
          rfid_key: "",
        },
        created_at: "",
        updated_at: "",
        company: {
          id: "",
          logo: null,
          name: "",
          manager_first: "",
          manager_last: "",
          address_one: "",
          address_two: "",
          post_code: "",
          country: "",
          created_at: "",
          updated_at: "",
        },
      },
      your_details: {
        user_id: "",
        user_email: "",
        user_password: "",
        user_firstname: "",
        user_lastname: "",
        user_vehicle: "",
        vehicle_id: "",
        company_id: "",
      },
      user: {
        address1: "",
        address2: "",
        post_code: "",
        country: "",
      },
    };
  },
  mounted() {
    this.invite.code = this.$route.query.code;

    // get screen data using code
    this.$http
      .get(this.$store.state.appURL + "invite/" + this.invite.code)
      .then((response) => {
        this.invite = response.data;

        if (this.invite.company.logo) {
          this.logoURL = this.$store.state.appURL + this.invite.company.logo;
        }

        this.your_details.workplace_only = this.invite.meta_data.workplace_only;
        this.your_details.user_firstname = this.invite.first_name;
        this.your_details.user_lastname = this.invite.last_name;
        this.your_details.user_email = this.invite.email;
        this.your_details.company_id = this.invite.company_id;

        if (this.invite.meta_data.user_id) {
          // this.$http.get(
          //     this.$store.state.appURL + "user/" + this.invite.meta_data.user_id
          //   )
          //   .then(response => {
          //     this.your_details.user_password = "";
          //     this.your_details.user_vehicle = response.data.user_vehicle;
          //     this.your_details.vehicle_id = response.data.vehicle_id;
          //   })
          //   .catch(error => {
          //     for (let [key, value] of Object.entries(error.response.data)) {
          //       this.errors.push(value[0]);
          //     }
          //   });
        }
      })
      .catch((error) => {
        for (let [key, value] of Object.entries(error.response.data)) {
          this.errors.push(value[0]);
        }
      });
  },
  methods: {
    addUser() {
      //validate  user details
      this.errors = [];
      // if an async request is processing
      if (!this.your_details.user_firstname) {
        this.errors.push("First name required.");
      }
      if (!this.your_details.user_lastname) {
        this.errors.push("Last name required.");
      }
      if (!this.invite.meta_data.user_id) {
      }
      if (!this.your_details.user_password) {
        this.errors.push("Password required.");
      }
      if (this.your_details.user_password.length <= 6) {
        this.errors.push("Password must be at least 6 char.");
      }
      if (!this.your_details.user_vehicle) {
        this.errors.push("Vehicle Make required.");
      }
      if (!this.your_details.vehicle_id) {
        this.errors.push("Vehicle Registration required.");
      }
      if (!this.errors.length) {
        this.your_details.code = this.invite.code;
        this.$http
          .post(this.$store.state.appURL + "user", this.your_details)
          .then((response) => {
            this.invite.meta_data.user_id = response.data.id;
            store.commit("updateApiToken", response.data.api_token);
            this.$http
              .patch(this.$store.state.appURL + "invite/" + this.invite.code, {
                meta_data: this.invite.meta_data,
                first_name: this.invite.first_name,
                user_lastname: this.invite.last_name,
                user_email: this.invite.email,
              })
              .then((response) => {
                router.push({
                  path: "get-card",
                  query: { code: this.invite.code },
                });
              })
              .catch((error) => {
                for (let [key, value] of Object.entries(error.response.data)) {
                  this.errors.push(value[0]);
                }
              });
          })
          .catch((error) => {
            if (error.response.data.constructor.name == "String") {
              this.errors.push(error.response.data);
            } else {
              for (let [key, value] of Object.entries(error.response.data)) {
                this.errors.push(value[0]);
              }
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.errors {
  bottom: 30px;
}
</style>
