<template>
  <div>
    <h1>
      <strong>£{{workPrice}}</strong>/kWh
    </h1>
    <p class="mt-1">bp pulse Workplace</p>
    <h1>
      <strong>£{{polarPrice}}</strong>/month
    </h1>
    <div>bp pulse</div>
    <p>Charge at any bp pulse post</p>
    <p>An average charge costs about £2.50</p>
    <p>Manage your data on ChargeVision app</p>
  </div>
</template>
<script>
export default {
  data: function() {
   return {

    }
  },
   props: {
    workPrice: String,
    polarPrice: String
   },
};
</script>
