<template>
  <div>
    <header-image :logo="logoURL"></header-image>
    <div class="container col-sm-8">
      <h1 class="col-sm-12 col-md-12 text-center mt-5 mb-4">
        bp pulse Workplace at
        <strong>{{ invite.company.name }}</strong>
      </h1>

      <div class="row">
        <div class="col-2">
          <journey :next-step="2"></journey>
        </div>
        <!-- What RFID -->
        <div class="col-10 text-justify">
          <p>What size RFID card would you like?</p>
          <div class="row justify-content-md-center">
            <div
              @click="pickCard()"
              :class="{ deal: invite.meta_data.rfid_type_fob }"
              class="card col-sm-12 col-md-12 col-lg-5"
            >
              <div>
                <svg width="80" viewBox="0 0 180 138">
                  <use xlink:href="#key-fob" />
                </svg>
              </div>
              <h1 class="dot">
                <font-awesome-icon :icon="['far', 'dot-circle']" />
              </h1>
              <h1 class="circle">
                <font-awesome-icon :icon="['far', 'circle']" />
              </h1>
              <p>Key-fob</p>
            </div>
            <div
              @click="pickCard()"
              :class="{ deal: !invite.meta_data.rfid_type_fob }"
              class="card col-sm-12 col-md-12 col-lg-5"
            >
              <div class="card-icon">
                <font-awesome-icon icon="credit-card" />
              </div>
              <h1 class="dot">
                <font-awesome-icon :icon="['far', 'dot-circle']" />
              </h1>
              <h1 class="circle">
                <font-awesome-icon :icon="['far', 'circle']" />
              </h1>
              <p>Credit Card size</p>
            </div>
          </div>

          <!-- Delivery -->

          <p>Where should your new RFID card be delivered?</p>
          <div class="row justify-content-md-center">
            <div
              @click="pickDelivery()"
              :class="{ deal: invite.meta_data.deliver_workplace }"
              class="card address col-sm-12 col-md-12 col-lg-5"
            >
              <div class="card-icon">
                <font-awesome-icon icon="building" />
              </div>
              <h1 class="dot">
                <font-awesome-icon :icon="['far', 'dot-circle']" />
              </h1>
              <h1 class="circle">
                <font-awesome-icon :icon="['far', 'circle']" />
              </h1>
              <p>My workplace</p>
              <p class="mb-0">{{ invite.company.name }}</p>
              <p class="mb-0">{{ invite.company.address_one }}</p>
              <p class="mb-0">{{ invite.company.address_two }}</p>
              <p class="mb-0">{{ invite.company.post_code }}</p>
              <p>{{ invite.company.country }}</p>
            </div>
            <div
              @click="pickDelivery()"
              :class="{ deal: !invite.meta_data.deliver_workplace }"
              class="card address col-sm-12 col-md-12 col-lg-5"
            >
              <div class="card-icon">
                <font-awesome-icon icon="home" />
              </div>
              <h1 class="dot">
                <font-awesome-icon :icon="['far', 'dot-circle']" />
              </h1>
              <h1 class="circle">
                <font-awesome-icon :icon="['far', 'circle']" />
              </h1>
              <p>My home address</p>
              <p v-if="home.address_one" class="mb-0">
                {{ invite.first_name }} {{ invite.last_name }}
              </p>
              <p class="mb-0">{{ home.address_one }}</p>
              <p class="mb-0">{{ home.address_two }}</p>
              <p class="mb-0">{{ home.post_code }}</p>
              <p>{{ home.country }}</p>
            </div>
          </div>
          <!-- Update Address -->
          <div class="row justify-content-md-center">
            <div class="card-off col-sm-12 col-md-12 col-lg-5"></div>
            <div class="card-off col-sm-12 col-md-12 col-lg-5">
              <div class="text-center">
                <div
                  class="btn btn-primary text-center"
                  @click="showAddress = true"
                >
                  Update Address
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 mt-4 mb-5 text-center">
            <div class="btn btn-primary" @click="saveDelivery()">Next</div>
            <div class="col-1"></div>
            <div class="btn btn-secondary" @click="previousPage()">
              Previous
            </div>
          </div>
          <div class="show-address" :class="{ show: showAddress }">
            <p>Please enter/update your contact details</p>
            <input
              type="text"
              class="form-control"
              placeholder="Address 1"
              v-model="home.address_one"
            />

            <input
              type="text"
              class="form-control"
              placeholder="Address 2"
              v-model="home.address_two"
            />
            <input
              type="text"
              id="post_code"
              class="form-control"
              placeholder="Post Code"
              v-model="home.post_code"
            />
            <input
              type="text"
              id="country"
              class="form-control"
              placeholder="Country"
              v-model="home.country"
            />
            <div class="col-sm-12 col-md-12 mt-4 mb-5 text-center">
              <div class="btn btn-primary" @click="getAddress()">
                Enter Address
              </div>
              <div class="col-1"></div>
              <div class="btn btn-secondary" @click="getAddress(true)">
                Cancel
              </div>
            </div>
            <!-- error section -->
            <div class="errors">
              <p v-if="errors.length">
                <em>Please correct the following error(s):</em>
              </p>
              <ul>
                <li v-for="(error, index) in errors" :key="index">
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <svg
        width="180px"
        height="138px"
        viewBox="0 0 180 138"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="display: none"
      >
        <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
        <title>key-fob</title>
        <desc>Created with Sketch.</desc>
        <g
          id="key-fob"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="icon-rfidhand"
            transform="translate(90.890598, 61.288897) scale(-1, 1) rotate(-36.000000) translate(-90.890598, -61.288897) translate(20.390598, 0.288897)"
          >
            <polygon
              id="Path"
              points="80.785 104.939 80.785 104.938 80.785 104.938"
            />
            <path
              d="M53.349,79.16 C52.616,82.234 56.684,84.96 56.725,84.986 C61.052,87.767 73.338,95.125 78.144,95.761 C81.763,96.208 85.298,95.38 87.115,94.798 C86.932,93.593 86.755,92.513 86.586,91.573 C84.91,92.1 82.986,92.231 80.907,91.957 C80.342,91.882 79.761,91.777 79.178,91.645 C74.112,90.493 63.834,81.398 58.057,75.978 C57.8934999,75.8320205 57.7626544,75.7522936 57.5769149,75.5585401 C57.4408617,75.4166167 57.2454319,75.3015141 57.1410084,75.2487714 C56.8940262,75.1240246 56.552759,75.004119 56.2657786,75.0640718 C55.9787982,75.1240246 55.7447236,75.4056932 55.431,75.679 C54.352,76.723 53.651,77.893 53.349,79.16 Z"
              id="Path"
              fill="currentColor"
              fill-rule="nonzero"
              opacity="0.6"
            />
            <path
              d="M12.9396561,11.6750263 L66.9396562,11.6750263 C71.3579342,11.6750263 74.9396562,15.2567483 74.9396562,19.6750263 L74.9396562,22.9070846 L75.0470669,20.6395034 C54.7475026,18.7662429 43.9023101,17.7907548 42.5114896,17.7130391 C40.5111048,17.5229502 37.2761406,19.7185183 36.0088815,21.813092 C33.9482627,25.2189608 34.1077311,31.1257278 37.670998,33.6908322 C37.670998,33.6908322 49.4613794,38.8430493 73.0421423,49.1474835 L73.0421423,49.1474835 C73.5658185,49.8450351 73.4248646,50.8350359 72.7273129,51.3587121 C72.4538279,51.5640267 72.12109,51.6750263 71.7791133,51.6750263 L12.9396561,51.6750263 C8.52137814,51.6750263 4.93965614,48.0933043 4.93965614,43.6750263 L4.93965614,19.6750263 C4.93965614,15.2567483 8.52137814,11.6750263 12.9396561,11.6750263 Z"
              id="Rectangle"
              fill="currentColor"
              transform="translate(39.993362, 31.675026) rotate(22.000000) translate(-39.993362, -31.675026) "
            />
            <path
              d="M107.448,37.389 C105.807,34.866 100.584,27.839 96.513,26.247 C92.946,24.851 88.638,24.643 86.387,24.643 L78.2126166,25.471178 C79.087114,26.4934955 79.5848245,27.7057525 79.7057481,29.107949 C79.8871336,31.2112437 79.8871336,30.0910218 79.7057481,32.1132448 C79.5848245,33.4613934 79.235722,34.6112723 78.6584407,35.5628812 L99.823,49.495 L97.1,53.382 L77.774,39.845 L50.081,24.535 C49.908,24.407 47.429,22.463 44.853,22.463 C43.652,22.463 42.614,22.899 41.77,23.76 C40.218,25.34 39.493,26.94 39.614,28.517 C39.852,31.58 43.053,34.024 43.085,34.048 C44.274,35.186 71.125,61.003 72.092,62.048 C77.051,67.413 80.315,71.038 81.79,72.819 C86.008,77.915 88.587,81.519 90.136,85.971 C91.542,90.01 92.901,102.241 93.181,104.875 C94.761,108.331 98.391,115.261 101.056,117.246 C103.391,118.986 108.033,120.443 111.386,121.184 L140.494,103.99 L107.448,37.389 Z"
              id="Path"
              fill="currentColor"
              fill-rule="nonzero"
              opacity="0.6"
            />
            <path
              d="M80.231,87.018 C81.203,87.24 82.123,87.352 82.965,87.352 C84.063,87.352 84.877,87.161 85.44,86.952 C84.09,83.412 81.803,80.278 78.135,75.846 C76.928,74.388 74.36,71.522 70.494,67.32 L70.476,67.369 C66.3452959,63.3098157 61.3800794,62.5463809 59.5,65.7603548 C56.8942496,70.2148548 62.1057504,73.3878101 63.732,74.758 C69.898,80.344 77.303,86.352 80.231,87.018 Z"
              id="Path"
              fill="currentColor"
              fill-rule="nonzero"
              opacity="0.6"
            />
          </g>
          <g
            id="Group"
            transform="translate(101.868868, 96.245364) rotate(-7.000000) translate(-101.868868, -96.245364) translate(87.868868, 62.745364)"
          >
            <path
              d="M26.9231908,12.798167 L26.9452411,9.88952827 C26.3619098,9.90718078 25.8130731,9.78889542 25.2987308,9.53467219 C24.7843886,9.28044896 24.3552781,8.91536572 24.0113994,8.43942246 L22.9142607,10.8224978 C23.1627742,11.2637374 23.6713627,11.6737625 24.4400261,12.0525729 C25.2086895,12.4313834 26.0364111,12.6799147 26.9231908,12.798167 Z"
              id="Oval"
              fill="currentColor"
              transform="translate(24.929751, 10.618795) scale(1, -1) rotate(-136.000000) translate(-24.929751, -10.618795) "
            />
            <path
              d="M8.34214556,9.69623106 C5.19902346,9.76266001 2.67148965,12.3313717 2.67148965,15.4904204 L2.67148964,31.9462166 C2.67148964,36.0883523 6.02935402,39.4462166 10.1714896,39.4462166 L19.1714896,39.4462166 C23.3136253,39.4462166 26.6714896,36.0883523 26.6714896,31.9462166 L26.6714896,17.0325531 C26.6714176,16.9923004 26.6714176,16.9923004 26.6710576,16.9520492 C26.6265965,12.8101522 23.2328828,9.48852408 19.0909858,9.53298515 L10.3639043,9.62666574 L11.1667006,12.4877475 L15.2894745,12.7259681 L15.3063364,16.0218051 L14.7911681,16.0088824 L9.57848686,15.8769811 C8.64933643,15.7905844 7.97863694,15.7008663 7.50179132,15.5892898 C5.67004243,14.9506954 5.94667492,12.7350333 7.97144287,12.3117682 C8.1147813,12.2818042 8.18686492,12.2684588 8.31410019,12.2494754 C8.49234429,12.2228817 8.75826195,12.1960852 9.11489681,12.1685893 L8.34214556,9.69623106 Z M10.2977367,13.0923846 L9.81231491,13.122818 C9.15045265,13.1643133 8.69664998,13.2034684 8.4616654,13.2385278 C8.35585038,13.2543152 8.30193014,13.2642979 8.17606356,13.2906095 C7.10330746,13.5148625 6.99844092,14.3547812 7.77718943,14.62959 C8.1472795,14.7136939 8.78217414,14.7986225 9.63731779,14.8792859 L14.3010765,14.9962741 L14.2942918,13.6701328 L10.3966556,13.444921 L10.2977367,13.0923846 Z"
              id="Rectangle"
              stroke="currentColor"
              fill="currentColor"
            />
            <polygon
              id="Rectangle"
              fill="currentColor"
              points="12.7321893 40.0981905 17.7321893 40.0981905 17.7321893 66.0981905 15.7689176 66.0981905 12.7321893 63.4363346"
            />
            <path
              d="M6.29409666,12.7298468 C8.67456917,14.0479596 13.8793346,14.3832227 18.0005706,12.0012076 C21.6386654,9.89844122 23.2835963,6.97850683 21.4378652,5.96159088 C19.5752844,4.93539152 18.5604527,8.86832018 14.8888267,10.0604045 C10.9037042,11.3542733 7.02256015,10.0873961 3.24539458,6.25977308 L0.738282936,8.13680719 C2.27624342,9.61561033 4.12818133,11.1466235 6.29409666,12.7298468 Z"
              id="Oval"
              fill="currentColor"
              transform="translate(11.469224, 9.774797) rotate(37.000000) translate(-11.469224, -9.774797) "
            />
          </g>
        </g>
      </svg>
    </div>
    <footer-menu></footer-menu>
  </div>
</template>

<script>
import store from "../../store";
import router from "../../router";
import { mapState, mapGetters, mapMutations } from "vuex";

import HeaderImage from "../../components/HeaderImage";
import Journey from "../../components/Journey.vue";
import FooterMenu from "../../components/FooterMenu";

export default {
  name: "GetCard",
  metaInfo: {
    title: "Get Card",
    // override the parent template and just use the above title only
    meta: [{ name: "description", content: "Get Card page" }],
  },
  components: {
    HeaderImage,
    Journey,
    FooterMenu,
  },
  data: function () {
    return {
      errors: [],
      successMsg: "",
      showAddress: false,
      logoURL: "",
      invite: {
        id: "",
        company_id: "",
        code: "",
        first_name: "",
        last_name: "",
        email: "",
        meta_data: {},
        created_at: "",
        updated_at: "",
        company: {
          id: "",
          logo: null,
          name: "",
          manager_first: "",
          manager_last: "",
          address_one: "",
          address_two: "",
          post_code: "",
          country: "",
          created_at: "",
          updated_at: "",
        },
      },
      user: {
        id: "",
        first_name: "",
        last_name: "",
        address_one: "",
        address_two: "",
        post_code: "",
        country: "",
        email: "",
        company_id: "",
        rfid_type_fob: null,
        workplace_only: null,
        deliver_workplace: null,
        verified_polar_member: null,
      },
      home: {
        first_name: "",
        last_name: "",
        address_one: "",
        address_two: "",
        post_code: "",
        country: "",
      },
    };
  },

  mounted() {
    this.invite.code = this.$route.query.code;
    // get screen data using code
    this.$http
      .get(this.$store.state.appURL + "invite/" + this.invite.code)
      .then((response) => {
        this.invite = response.data;

        if (this.invite.company.logo) {
          this.logoURL = this.$store.state.appURL + this.invite.company.logo;
        }

        this.user.first_name = this.invite.first_name;
        this.user.last_name = this.invite.last_name;

        if (!this.invite.meta_data.deliver_workplace) {
          // load home address

          if (this.invite.meta_data.rfid_key) {
            this.$http
              .get(
                this.$store.state.appURL +
                  "rfid-delivery/" +
                  this.invite.meta_data.rfid_key,
                  {
                    headers: {
                      "Authorization": this.$store.state.api_token,
                    }
                  })
              .then((response) => {
                this.home = response.data;
                this.home.first_name = this.user.first_name;
                this.home.last_name = this.user.last_name;
              })
              .catch((error) => {
                for (let [key, value] of Object.entries(error.response.data)) {
                  this.errors.push(value[0]);
                }
              });
          }
        }
      })
      .catch((error) => {
        for (let [key, value] of Object.entries(error.response.data)) {
          this.errors.push(value[0]);
        }
      });
  },
  methods: {
    pickCard() {
      this.invite.meta_data.rfid_type_fob = !this.invite.meta_data
        .rfid_type_fob;
    },
    pickDelivery() {
      this.invite.meta_data.deliver_workplace = !this.invite.meta_data
        .deliver_workplace;
      if (
        (!this.invite.meta_data.deliver_workplace && !this.home.address_one) ||
        !this.home.post_code
      ) {
        this.showAddress = true;
      }
    },
    getAddress(cancel) {
      if (cancel) {
        this.showAddress = false;
        this.invite.meta_data.deliver_workplace = true;
        this.errors = [];
      } else {
        //validate  user details
        this.errors = [];

        if (!this.home.address_one) {
          this.errors.push("Address one required.");
        }
        if (!this.home.post_code) {
          this.errors.push("Postcode required.");
        } else {
          this.showAddress = false;
        }
      }
    },
    saveDelivery() {
      // set up user record
      this.user.company_id = this.invite.company_id;
      this.user.email = this.invite.email;
      this.user.deliver_workplace = this.invite.meta_data.deliver_workplace;
      this.user.rfid_type_fob = this.invite.meta_data.rfid_type_fob;
      this.user.workplace_only = this.invite.meta_data.workplace_only;

      if (this.user.deliver_workplace) {
        this.user.first_name = this.invite.company.name;
        this.user.address_one = this.invite.company.address_one;
        this.user.address_two = this.invite.company.address_two;
        this.user.post_code = this.invite.company.post_code;
        this.user.country = this.invite.company.country;
      } else {
        this.user.first_name = this.invite.first_name;
        this.user.last_name = this.invite.last_name;
        this.user.address_one = this.home.address_one;
        this.user.address_two = this.home.address_two;
        this.user.post_code = this.home.post_code;
        this.user.country = this.home.country;
      }

      // update store
      if (this.invite.meta_data.rfid_key) {
        // if you have an rfid_key don't update the user record for a second time just update invite
        this.$http
          .patch(
            this.$store.state.appURL + "invite/" + this.invite.code,
            {
              meta_data: this.invite.meta_data,
            }
          )
          .then((response) => {
            router.push({ path: "payment", query: { code: this.invite.code } });
          })
          .catch((error) => {
            for (let [key, value] of Object.entries(error.response.data)) {
              this.errors.push(value[0]);
            }
          });
      } else {
        this.$http
          .post(this.$store.state.appURL + "rfid-delivery", this.user,
          {
            headers: {
              "Authorization": this.$store.state.api_token,
            }
          })
          .then((response) => {
            this.invite.meta_data.rfid_key = response.data.id;

            this.$http
              .patch(
                this.$store.state.appURL + "invite/" + this.invite.code,
                {
                  meta_data: this.invite.meta_data,
                }
              )
              .then((response) => {
                router.push({
                  path: "payment",
                  query: { code: this.invite.code },
                });
              })
              .catch((error) => {
                for (let [key, value] of Object.entries(error.response.data)) {
                  this.errors.push(value[0]);
                }
              });
          })
          .catch((error) => {
            for (let [key, value] of Object.entries(error.response.data)) {
              this.errors.push(value[0]);
            }
          });
      }
    },
    previousPage() {
      router.push({ path: "your-details", query: { code: this.invite.code } });
    },
  },
};
</script>
<style scoped>
.card-icon {
  font-size: 3em;
}
.show-address {
  position: absolute;
  height: 0px;
  width: 100%;
  padding-right: 30px;
  background-color: #fff;
  bottom: 0px;
  transition: height 1s;
  overflow: hidden;
  padding-left: 15px;
  left: 0px;
}
.show-address input {
  margin-bottom: 5px;
}
.show {
  height: 610px;
  transition: height 1s;
}
.card-off {
  border: 2px solid none;
  padding: 10px 15px;
  margin: 0 15px 15px 15px;
}
.errors {
  bottom: 30px;
}
.address {
  height: 300px;
}
/* X X X X DESKTOP  X X X  */

@media (min-width: 993px) {
  .show {
    height: 610px;
  }
}
</style>

