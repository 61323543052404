<template>
  <div>
    <div class="ribbon">
      <div class="banner-box container col-sm-8">
        <img
          :src="require('../../src/img/bg-register.jpg')"
          alt="bp pulse App Screens"
        />

        <div class="banner-text col-sm-6 col-md-6 col-offset-lg-2 col-lg-5">
          <div class="text-white">
            <h3>Pricing</h3>
            <p class="lead text-white">
              How much does it cost to charge on bp pulse?
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container col-sm-8">
      <div class="mt-4 mb-4 col-lg-12 text-center col-xs-12"></div>

      <!-- ICONS -->
      <div class="row justify-content-xl-center">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="mb-2">
            <!-- 1 -->
            <div
              class="col-sm-3 col-md-3 col-lg-2 col-xl-2 icon fa-stack fa-3x icon-points"
            >
              <font-awesome-icon class="fa-stack-2x cmblue" icon="circle" />
              <font-awesome-icon
                class="fa-stack-1x fa-inverse"
                icon="pound-sign"
              />
            </div>
            <div
              class="col-sm-8 col-md-8 col-lg-9 col-xl-10 float-right bg-grey"
            >
              <h4>
                Need help locating a charge point? How much does a bp pulse
                Membership cost?
              </h4>
              <p>
                bp pulse is just £{{ this.$store.state.company_polar_price }}
                per month for access to over 7,000 charge points, with the first
                3 months absolutely free! The majority of our charging points
                are then free to use, and the others are from just 28p per kWh
                (inc. VAT). To see exactly which charge points are free or to
                view full tariffs, please visit our
                <a href="/live-map">live map.</a>
              </p>
            </div>
          </div>
          <div class="mb-2">
            <!-- 2 -->
            <div
              class="col-sm-3 col-md-3 col-lg-2 col-xl-2 icon fa-stack fa-3x icon-points"
            >
              <font-awesome-icon class="fa-stack-2x cmblue" icon="circle" />
              <font-awesome-icon
                class="fa-stack-1x fa-inverse"
                icon="pound-sign"
              />
            </div>
            <div
              class="col-sm-8 col-md-8 col-lg-9 col-xl-10 float-right bg-grey"
            >
              <h4>How much does bp pulse cost?</h4>
              <p>
                If you prefer a more instant way of accessing our charging
                points, you can download the bp pulse app, which you will be
                able to use to access our charging points on an adhoc basis. You
                will need to top up the app with credit and start and stop your
                charging via the app (meaning units in areas without much mobile
                signal will be more difficult to use). There is a minimum top-up
                of £5.
              </p>
            </div>
          </div>
          <div class="mb-2">
            <!-- 3 -->
            <div
              class="col-sm-3 col-md-3 col-lg-2 col-xl-2 icon fa-stack fa-3x icon-points"
            >
              <font-awesome-icon class="fa-stack-2x cmblue" icon="circle" />
              <font-awesome-icon
                class="fa-stack-1x fa-inverse"
                icon="credit-card"
              />
            </div>
            <div
              class="col-sm-8 col-md-8 col-lg-9 col-xl-10 float-right bg-grey"
            >
              <h4>Can I use my contactless bank card?</h4>
              <p>
                On our rapid chargers (50kW) and our ultra-fast chargers (150kW)
                units it is possible to use your contactless bank card to start,
                pay for and stop a charge. There will be a reserve of £15 taken
                on 50kW contactless units before a charge has taken place, there
                will be a reserve of £30 taken on 150kW units.
              </p>
            </div>
          </div>
          <div class="mb-2">
            <!-- 4 -->
            <div
              class="col-sm-3 col-md-3 col-lg-2 col-xl-2 icon fa-stack fa-3x icon-points"
            >
              <font-awesome-icon class="fa-stack-2x cmblue" icon="circle" />
              <font-awesome-icon class="fa-stack-1x fa-inverse" icon="bolt" />
            </div>
            <div
              class="col-sm-8 col-md-8 col-lg-9 col-xl-10 float-right bg-grey"
            >
              <h4>What is the tariff price of electricity on bp pulse?</h4>
              <table class="table table-hover" style="width: 100%">
                <tbody>
                  <tr>
                    <th>Tariff (per kWh)</th>
                    <th>AC charger</th>
                    <th>43 AC / 50kW DC charger</th>
                    <th>150kW DC charger</th>
                  </tr>
                  <tr>
                    <td>bp pulse Subscription</td>
                    <td>28p</td>
                    <td>32p</td>
                    <td>38p</td>
                  </tr>
                  <tr>
                    <td>bp pulse Registered User</td>
                    <td>33p</td>
                    <td>38p</td>
                    <td>44p</td>
                  </tr>
                  <tr>
                    <td>Contactless</td>
                    <td>35p</td>
                    <td>40p</td>
                    <td>50p</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="mb-2">
            <!-- 5 -->
            <div
              class="col-sm-3 col-md-3 col-lg-2 col-xl-2 icon fa-stack fa-3x icon-points"
            >
              <font-awesome-icon class="fa-stack-2x cmblue" icon="circle" />
              <font-awesome-icon class="fa-stack-1x fa-inverse" icon="plus" />
            </div>
            <div
              class="col-sm-8 col-md-8 col-lg-9 col-xl-10 float-right bg-grey"
            >
              <h4>Are there any additional charges I may have to pay?</h4>
              <p>
                To ensure these charging units are being used appropriately, we
                listened to our customer feedback, and introduced an over-stay
                fee policy in May 2018. A £10 fee applies per hour after 90
                minutes of charging at the unit, and this is charged directly
                from your bp pulse credit or added to your monthly bp pulse
                bill.
              </p>
            </div>
          </div>
          <div class="mb-2">
            <!-- 6 -->
            <div
              class="col-sm-3 col-md-3 col-lg-2 col-xl-2 icon fa-stack fa-3x icon-points"
            >
              <font-awesome-icon class="fa-stack-2x cmblue" icon="circle" />
              <font-awesome-icon class="fa-stack-1x fa-inverse" icon="bolt" />
            </div>
            <div
              class="col-sm-8 col-md-8 col-lg-9 col-xl-10 float-right bg-grey"
            >
              <h4>What about posts which aren’t hosted on bp pulse?</h4>
              <p>
                There will be some posts which are not owned by the network, but
                owned by the site host. There will be some posts which have
                separate tariffs which has been determined by the host. To view
                a charging units individual price tariff, please View our live
                map and search for the unit/address.
              </p>
            </div>
          </div>
          <div class="mb-2">
            <!-- 7 -->
            <div
              class="col-sm-3 col-md-3 col-lg-2 col-xl-2 icon fa-stack fa-3x icon-points"
            >
              <font-awesome-icon class="fa-stack-2x cmblue" icon="circle" />
              <font-awesome-icon
                class="fa-stack-1x fa-inverse"
                icon="history"
              />
            </div>
            <div
              class="col-sm-8 col-md-8 col-lg-9 col-xl-10 float-right bg-grey"
            >
              <h4>How do I view my charging history?</h4>
              <p>
                <strong>bp pulse</strong> – log in to your bp pulse account and
                click on ‘charges’ you will be sent a monthly invoice to your
                registered email address with the costs.
              </p>

              <p>
                <strong>Contactless</strong> – should you need a receipt, you
                will need to email <a>heretohelp@bp.com</a> with your contact
                information, the charging point number and location, the date
                and time charged and the amount.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-menu></footer-menu>
  </div>
</template>

<script>
import store from "../store";
import router from "../router";
import { mapState, mapGetters, mapMutations } from "vuex";

import HeaderImage from "../components/HeaderImage";
import FooterMenu from "../components/FooterMenu";

export default {
  name: "Pricing",
  metaInfo: {
    title: "Pricing",
    // override the parent template and just use the above title only
    meta: [{ name: "description", content: "Pricing page" }],
  },
  components: {
    HeaderImage,
    FooterMenu,
  },
  data: function () {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.icon {
  height: 335px;
}
.bg-grey {
  background-color: #ddd;
  padding: 10px 20px;
  height: 330px;
  margin-bottom: 15px;
}
.banner {
  border: 2px solid #00aeef;
  color: #00aeef;
  border-radius: 20px;
  text-align: center;
  padding: 10px 15px;
  margin-bottom: 15px;
}

.banner h1 {
  color: #00aeef;
}
.banner-text {
  position: absolute;
  top: 15px;
}

.banner-box {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  height: 300px;
}
.banner-box img {
  width: 100%;
  height: auto;
}
.banner-box .col-lg-5 {
  max-width: 100%;
}
.ribbon {
  background-color: #050;
  width: 100%;
}
/* X X X X MOBILE  PORTRAIT X X X  */

@media only screen and (max-width: 768px) and (orientation: portrait) {
}

/* X X X X Landscape TABLET  X X X  */

@media (min-width: 1090px) {
  .banner-text {
    position: absolute;
    top: 0px;
    margin-top: 2%;
  }
}
/* X X X X DESKTOP  X X X  */

@media (min-width: 1500px) {
}
</style>
