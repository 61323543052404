<template>
  <div>
    <div class="ribbon">
      <div class="video-box container col-sm-8">
        <div class="video-tint">
          <video preload="auto" autoplay loop muted controls>
            <source :src="require('../media/header.mp4')" type="video/mp4" />
            <source :src="require('../media/header.webm')" type="video/webm" />
          </video>
        </div>

        <div class="video-text col-sm-6 col-md-6 col-offset-lg-2 col-lg-5">
          <div class="text-white mt-4">
            <h1 class="text-white">
              Welcome to bp pulse,
              <br />the UK’s largest charging network
            </h1>
            <p>
              bp pulse allows you to charge at over 7,000 points across the
              <br />UK for just £7.85 a month, with 3 free months* for all new
              members.
            </p>
            <p>
              <a href="https://www.bppulse.co.uk/register" class="btn btn-primary mr-2"
                >Sign up now</a
              >
              <router-link to="/activate" class="btn btn-secondary"
                >Activate Card</router-link
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container col-sm-8">
      <div class="mt-4 mb-4 col-lg-12 text-center col-xs-12">
        <h2>What a bp pulse membership gives you</h2>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
          <div class="">
            <!-- 1 -->
            <div
              class="col-sm-3 col-md-3 col-lg-3 col-xl-4 icon fa-stack fa-3x icon-points"
            >
              <font-awesome-icon class="fa-stack-2x cmblue" icon="circle" />
              <font-awesome-icon class="fa-stack-1x fa-inverse" icon="plug" />
            </div>
            <div class="col-sm-8 col-md-8 col-lg-8 float-right bg-grey">
              Access to over 7,000 charge points across the UK with an
              easy-to-use card or key fob
            </div>
          </div>

          <div class="mb-2">
            <!-- 2 -->
            <div
              class="col-sm-3 col-md-3 col-lg-3 col-xl-4 icon fa-stack fa-3x icon-points"
            >
              <font-awesome-icon class="fa-stack-2x cmblue" icon="circle" />
              <font-awesome-icon
                class="fa-stack-1x fa-inverse"
                icon="question"
              />
            </div>
            <div class="col-sm-8 col-md-8 col-lg-8 float-right bg-grey">
              We’re always here for you on our 24/7 friendly emergency helpline
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
          <div class="mb-2">
            <!-- 1 -->
            <div
              class="col-sm-3 col-md-3 col-lg-3 col-xl-4 icon fa-stack fa-3x icon-points"
            >
              <font-awesome-icon class="fa-stack-2x cmblue" icon="circle" />
              <font-awesome-icon class="fa-stack-1x fa-inverse" icon="bolt" />
            </div>
            <div class="col-sm-8 col-md-8 col-lg-8 float-right bg-grey">
              Some of our charges are free to use with this subscription, with
              the others from just 28p per kWh. For more click
              <router-link to="/pricing">pricing information</router-link>
            </div>
          </div>

          <div class="mb-2">
            <!-- 2 -->
            <div
              class="col-sm-3 col-md-3 col-lg-3 col-xl-4 icon fa-stack fa-3x icon-points"
            >
              <font-awesome-icon class="fa-stack-2x cmblue" icon="circle" />
              <font-awesome-icon class="fa-stack-1x fa-inverse" icon="check" />
            </div>
            <div class="col-sm-8 col-md-8 col-lg-8 float-right bg-grey">
              bp pulse for business? Visit our
              <a href="https://network.bppulse.co.uk/corporate/"
                >corporate sign up</a
              >
              page to enquire
            </div>
          </div>
        </div>
      </div>
      <!-- NEWS -->
      <div class="banner p-4 mt-4 mb-4 col-lg-12 text-center col-xs-12">
        <h2>The latest from the bp pulse…</h2>
        <!--TICKER TAPE -->
        <p>
          {{ ticker + 1 }} __
          <a :href="news[ticker].url">{{ news[ticker].text }}</a>
        </p>
        <p>
          {{ ticker + 2 }} __
          <a :href="news[ticker + 1].url">{{ news[ticker + 1].text }}</a>
        </p>

        <a href="https://bpchargemaster.com/news/" class="btn btn-primary"
          >News</a
        >
      </div>
      <!-- APP advert -->

      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-9">
          <h2 class="text-left-md text-centre">
            bp pulse is the largest public network of electric vehicle charging
            points in the UK
          </h2>
          <div class="text-left-md text-centre">
            <p class="lead mb-3">
              Download the bp pulse app; our membership subscription service.
            </p>
          </div>
          <div class="col-md-6 float-left"></div>
          <div class="mt-4 col-md-6 float-right text-center col-xs-12">
            <p class="text-muted">Download Now on Google Play and App Store</p>

            <a
              href="https://play.google.com/store/apps/details?id=com.chargemaster.polar_plus"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                class="mt-4 mb-4"
                :src="require('../../src/img/google-play-store.svg')"
                alt="ios download on play store bp pulse"
                width="60%"
              />
            </a>
            <a
              href="https://itunes.apple.com/gb/app/polar-plus/id1352267609?mt=8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                class="mb-4"
                :src="require('../../src/img/apple-app-store.svg')"
                alt="ios download on app store bp pulse"
                width="60%"
              />
            </a>
          </div>
        </div>
        <div class="map-img mt-4 col-md-3">
          <img
            class="img-responsive"
            :src="require('../../src/img/polar-map.png')"
            alt="Polar map charger locations"
          />
        </div>
      </div>
      <p class="text-muted text-center"></p>
      <!-- REGISTER -->
      <div class="banner p-4 col-lg-12 text-center col-xs-12">
        <h3>
          Register today and receive FREE membership for the first 3 months*
        </h3>
        <p>
          We’ll send out an easy-to-use card or key fob directly to your door
          within 3-5 working days. This will give you unlimited access to the
          UK’s largest public charging network of over 7,000 charge points
          nationwide.
        </p>
        <p>
          <a class="btn btn-primary" href="https://www.bppulse.co.uk/register">SIGN UP NOW</a>
        </p>
        <p>
          *you will still be charged for any electricity used on a per kWh
          basis, if you use a post which has a chargeable tariff.
        </p>

      </div>
    </div>
    <footer-menu></footer-menu>
  </div>
</template>

<script>
import store from "../store";
import router from "../router";
import { mapState, mapGetters, mapMutations } from "vuex";

import HeaderImage from "../components/HeaderImage";
import FooterMenu from "../components/FooterMenu";

export default {
  name: "About",
  metaInfo: {
    title: "Your About",
    // override the parent template and just use the above title only
    meta: [{ name: "description", content: "About page" }],
  },
  components: {
    HeaderImage,
    FooterMenu,
  },
  data: function () {
    return {
      ticker: 0,
      news: [
        // bulletin
        {
          url:
            "https://polar-network.com/first-bp-chargemaster-rapid-chargers-installed-in-wolverhampton/",
          text:
            "First bp Chargemaster rapid chargers installed in Wolverhampton",
        },
        {
          url:
            "https://polar-network.com/bp-chargemaster-continues-to-grow-rolling-out-ultra-fast-charging-on-bp-forecourts-across-the-uk/",
          text:
            "bp Chargemaster continues to grow, rolling out ultra-fast charging on BP forecourts across the UK",
        },
        {
          url:
            "https://polar-network.com/bp-chargemaster-electrifies-lincolnshire-with-rapid-chargers-at-convenience-retailers-sites/",
          text:
            "bp Chargemaster electrifies Lincolnshire with rapid chargers at convenience retailer’s sites",
        },
        {
          url:
            "https://polar-network.com/new-polar-installations-july-2019-update/",
          text: "New Polar Installations: July 2019 update",
        },
        {
          url:
            "https://polar-network.com/polar-network-pricing-and-access-changes-from-5th-august-2019/",
          text: "bp pulse pricing and access changes from 5th August 2019",
        },
        {
          url:
            "https://polar-network.com/200-rapid-chargers-to-be-installed-at-mitchells-butlers-sites/",
          text:
            "200 rapid chargers to be installed at Mitchells & Butlers’ sites",
        },
        {
          url:
            "https://polar-network.com/twin-rapid-chargers-installed-at-co-op-saxon-cross/",
          text: "Twin rapid chargers installed at Co-Op Saxon Cross",
        },
        {
          url:
            "https://polar-network.com/electric-charging-continues-to-grow-across-bedford/",
          text: "Electric charging continues to grow across Bedford",
        },
        {
          url:
            "https://polar-network.com/concierge-camping-installs-second-rapid-charger/",
          text: "Concierge Camping installs second rapid charger",
        },
        {
          url:
            "https://polar-network.com/chelmsford-city-council-encourages-uptake-of-electric-vehicles-with-rapid-charger-installations/",
          text:
            "Chelmsford City Council encourages uptake of electric vehicles with rapid charger installations",
        },
      ],
    };
  },
  mounted() {
    this.tickerTape();
  },
  methods: {
    tickerTape() {
      if (this.ticker <= 8) {
        setTimeout(() => {
          this.ticker += 1;
          this.tickerTape();
        }, 5000);
      } else {
        this.ticker = 0;
        this.tickerTape();
      }
    },
  },
};
</script>

<style scoped>
.banner {
  border: 2px solid #00aeef;
  color: #00aeef;
  border-radius: 20px;
  text-align: center;
  padding: 10px 15px;
  margin-bottom: 15px;
}

.banner h1 {
  color: #00aeef;
}

.video-box {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  height: 500px;
  z-index: 1;
}
.ribbon {
  background-color: #050;
  width: 100%;
}
.video-tint:before {
  opacity: 0.3;
  background: #050;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

video {
  position: absolute;
  z-index: -1;
  left: -25%;
  width: auto;
  height: 100%;
}
.video-box .col-lg-5 {
  max-width: 100%;
}
.map-img {
  display: none;
}
/* X X X X MOBILE  PORTRAIT X X X  */

@media only screen and (max-width: 768px) and (orientation: portrait) {
  .video-box .col-lg-5 {
    text-align: center;
  }
}

/* X X X X Landscape TABLET  X X X  */

@media (min-width: 1090px) {
  .video-box {
    height: 400px;
  }
  .video-text {
    margin-top: 2%;
  }
  video {
    left: 0%;
    width: 100%;
    height: auto;
  }
  .map-img {
    display: block;
  }
}
/* X X X X DESKTOP  X X X  */

@media (min-width: 1500px) {
  .video-text {
    margin-top: 12%;
  }
  .video-box {
    height: 540px;
  }
  video {
    left: 0%;
    width: 100%;
    height: auto;
  }
  .map-img {
    display: block;
  }
}
</style>
