<template>
  <div>
    <h1>
      <strong>£{{workPrice}}</strong>/kWh
    </h1>
    <p>No extra fees; just pay for electricity</p>
    <p>An average charge costs about £2.50</p>
    <p>Manage your data on ChargeVision app</p>
  </div>
</template>
<script>
export default {
  data: function() {
    return {

    }
  },
   props: {
    workPrice: String
   }
};
</script>
