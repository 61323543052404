<template>
  <div>
    <div class="ribbon">
      <div class="banner-box container col-sm-8">
        <img
          :src="require('../../src/img/bg-register.jpg')"
          alt="bp pulse App Screens"
        />

        <div class="banner-text col-sm-6 col-md-6">
          <div class="text-white">
            <h3>Help</h3>
            <p>What do you need help with today?</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container col-sm-8">
      <div class="mt-4 mb-4 col-lg-12 text-center col-xs-12"></div>

      <!-- ICONS -->

      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
          <div class="mb-2">
            <!-- 1 -->
            <div
              class="col-sm-3 col-md-3 col-lg-3 col-xl-4 icon fa-stack fa-3x icon-points"
            >
              <font-awesome-icon class="fa-stack-2x cmblue" icon="circle" />
              <font-awesome-icon
                class="fa-stack-1x fa-inverse"
                icon="map-marker-alt"
              />
            </div>
            <div class="col-sm-8 col-md-8 col-lg-8 float-right bg-grey">
              <p>
                Need help locating a charge point?
                <a href="https://network.bppulse.co.uk/live-map/"
                  >view our Live Map</a
                >
              </p>
            </div>
          </div>
          <div class="mb-2">
            <!-- 2 -->
            <div
              class="col-sm-3 col-md-3 col-lg-3 col-xl-4 icon fa-stack fa-3x icon-points"
            >
              <font-awesome-icon class="fa-stack-2x cmblue" icon="circle" />
              <font-awesome-icon
                class="fa-stack-1x fa-inverse"
                icon="pound-sign"
              />
            </div>
            <div class="col-sm-8 col-md-8 col-lg-8 float-right bg-grey">
              <p>
                Find out about our
                <a href="https://network.bppulse.co.uk/pricing/"
                  >charging costs</a
                >.
              </p>
            </div>
          </div>
          <div class="mb-2">
            <!-- 3 -->
            <div
              class="col-sm-3 col-md-3 col-lg-3 col-xl-4 icon fa-stack fa-3x icon-points"
            >
              <font-awesome-icon class="fa-stack-2x cmblue" icon="circle" />
              <font-awesome-icon class="fa-stack-1x fa-inverse" icon="plus" />
            </div>
            <div class="col-sm-8 col-md-8 col-lg-8 float-right bg-grey">
              <p>
                Need Instant access to our network, download our
                <a
                  href="https://bpchargemaster.com/bppulse-app%e2%80%8b/"
                  target="_blank"
                  rel="noopener noreferrer"
                  >bp pulse</a
                >
                app or use your
                <a href="https://network.bppulse.co.uk/contactless/"
                  >contactless</a
                >
                bank payment.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
          <div class="mb-2">
            <!-- 4 -->
            <div
              class="col-sm-3 col-md-3 col-lg-3 col-xl-4 icon fa-stack fa-3x icon-points"
            >
              <font-awesome-icon class="fa-stack-2x cmblue" icon="circle" />
              <font-awesome-icon class="fa-stack-1x fa-inverse" icon="user" />
            </div>
            <div class="col-sm-8 col-md-8 col-lg-8 float-right bg-grey">
              <p>
                Need to
                <a href="/contact">contact us</a> and speak to one of our
                friendly team?
              </p>
            </div>
          </div>
          <div class="mb-2">
            <!-- 5 -->
            <div
              class="col-sm-3 col-md-3 col-lg-3 col-xl-4 icon fa-stack fa-3x icon-points"
            >
              <font-awesome-icon class="fa-stack-2x cmblue" icon="circle" />
              <font-awesome-icon class="fa-stack-1x fa-inverse" icon="bolt" />
            </div>
            <div class="col-sm-8 col-md-8 col-lg-8 float-right bg-grey">
              <p>
                Not sure how to use a charge point? Don’t know what cable to
                use?
                <a href="https://network.bppulse.co.uk/user-guides/"
                  >Read our user guides here</a
                >.
              </p>
            </div>
          </div>
          <div class="mb-2">
            <!-- 6 -->
            <div
              class="col-sm-3 col-md-3 col-lg-3 col-xl-4 icon fa-stack fa-3x icon-points"
            >
              <font-awesome-icon class="fa-stack-2x cmblue" icon="circle" />
              <font-awesome-icon
                class="fa-stack-1x fa-inverse"
                icon="question"
              />
            </div>
            <div class="col-sm-8 col-md-8 col-lg-8 float-right bg-grey">
              <p>
                Still have a question? Read our
                <a href="https://bpchargemaster.com/faqs/">FAQs</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-menu></footer-menu>
  </div>
</template>

<script>
import store from "../store";
import router from "../router";
import { mapState, mapGetters, mapMutations } from "vuex";

import HeaderImage from "../components/HeaderImage";
import FooterMenu from "../components/FooterMenu";

export default {
  name: "Help",
  metaInfo: {
    title: "Help",
    // override the parent template and just use the above title only
    meta: [{ name: "description", content: "Help page" }],
  },
  components: {
    HeaderImage,
    FooterMenu,
  },
  data: function () {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.banner {
  border: 2px solid #00aeef;
  color: #00aeef;
  border-radius: 20px;
  text-align: center;
  padding: 10px 15px;
  margin-bottom: 15px;
}

.banner h1 {
  color: #00aeef;
}
.banner-text {
  position: absolute;
  top: 15px;
}

.banner-box {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  height: 300px;
}
.banner-box img {
  width: 100%;
  height: auto;
}
.banner-box .col-lg-5 {
  max-width: 100%;
}
.ribbon {
  background-color: #050;
  width: 100%;
}
/* X X X X MOBILE  PORTRAIT X X X  */

@media only screen and (max-width: 768px) and (orientation: portrait) {
}

/* X X X X Landscape TABLET  X X X  */

@media (min-width: 1090px) {
  .banner-text {
    position: absolute;
    top: 0px;
    margin-top: 2%;
  }
}
/* X X X X DESKTOP  X X X  */

@media (min-width: 1500px) {
}
</style>
