<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="site-logo">
        <a href="/">
          <img src="https://www.bppulse.co.uk/menu-logo.png?w=384&fm=webp" />
        </a>
      </div>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <ul class="navbar-nav">
            <li v-if="!this.$store.state.authenticated === true">
              <router-link
                to="/"
                class="nav-item nav-link"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >Log In</router-link>
            </li>
            <li v-if="this.$store.state.authenticated === true">
              <router-link
                to="/company-details"
                class="nav-item nav-link"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >Company Details</router-link>
            </li>
            <li v-if="this.$store.state.authenticated === true">
              <router-link
                to="/add-invites"
                class="nav-item nav-link"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >Add Invites</router-link>
            </li>
            <li v-if="this.$store.state.authenticated === true">
              <div
                @click="logOut()"
                class="nav-item nav-link"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >Log Out</div>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <router-view />
  </div>
</template>
<script>
import router from "./router";
import { mapGetters } from "vuex";

export default {
  name: "App",
  metaInfo() {
    return {
      meta: [
        { name: "apple-mobile-web-app-title", content: "ChargeVision Admin" },
        { name: "keywords", content: "ChargeVision Admin" }
      ],
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Start",
      // all titles will be injected into this template
      titleTemplate: "%s | "
    };
  },
  data: function() {
    return {};
  },
  computed: {
    loggedIn: function() {
      this.$store.commit("updateAuthenticated", false);
    }
  },
  methods: {
    logOut() {
      this.$store.commit("updateAuthenticated", false);
      router.push("/");
    }
  }
};
</script>
<style></style>
