<template>
  <div>
    <header-image></header-image>
    <div class="container col-sm-8">
      <h1 class="mt-4 mb-4">Log In</h1>
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <label>Email</label>
          <input
            type="email"
            id="email"
            class="form-control"
            v-model="userEmail"
          />
          <label>Password</label>
          <input
            type="password"
            id="password"
            class="form-control"
            v-model="userPassword"
          />
        </div>

        <div class="col-sm-12 col-md-12 mt-4 text-right">
          <div class="btn btn-primary" @click="logIn()">Log In</div>
          <div class="small-text pt-2">Forgot password?</div>
          <router-link
              to="/forgot-password"
              class="btn btn-secondary mt-1">
            Password Reset
          </router-link>

        </div>

        <!-- error section -->
        <div class="errors">
          <p v-if="errors.length">
            <em>Please correct the following error(s):</em>
          </p>
          <ul>
            <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../store";
import router from "../router";
import { mapState, mapGetters, mapMutations } from "vuex";

import HeaderImage from "../components/HeaderImage";

export default {
  name: "LogIn",
  metaInfo: {
    title: "Log In",
    // override the parent template and just use the above title only
    meta: [{ name: "description", content: "Log In page" }],
  },
  components: {
    HeaderImage,
  },
  data: function () {
    return {
      errors: [],
      successMsg: "",
      userEmail: "",
      userPassword: "",
      invite: {},
      user: {
        user_id: "",
      }
    };
  },
  computed: {
    authenticated: function () {
      return this.$store.state.authenticated;
    },
  },
  methods: {
    async logIn() {
      this.invite = this.$store.state.invite;
      this.errors = [];

      if (!this.userEmail) {
        this.errors.push("Email required.");
      } else if (!this.validEmail(this.userEmail)) {
        this.errors.push("Valid email required.");
      }

      if (!this.userPassword) {
        this.errors.push("Password required.");
      }

      if (!this.errors.length) {
        this.$http
          .post(
            this.$store.state.appURL + "login",
            {
              user_email: this.userEmail,
              user_password: this.userPassword,
            }
          )
          .then((response) => {
            this.user = response.data.user;
            this.invite.owner_id = this.user.user_id;
            this.invite.company.id = response.data.user.company_id;

            store.commit("updateApiToken", response.data.api_token);
            store.commit("updateAuthenticated", true);
            store.commit("updateInvite", this.invite);
            router.push("company-details");
          })
          .catch((e) => {
            this.errors.push(
              "Login failed, please check the details you entered"
            );
          });
      }
    },

    validEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>

