<template>
  <div>
    <gmap-map :center="center" :zoom="zoom" class="g-map">
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="center=m.position"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  data() {
    return {
      // contact map - set to location of BPChargemaster - Linford Wood
      zoom: 13,
      center: { lat: 52.057862, lng: -0.766733 },
      markers: [
        {
          position: {
            lat: 52.057862,
            lng: -0.766733
          }
        }
      ],
      places: [],
      currentPlace: null
    };
  },
  methods: {

  }
};
</script>
<style scoped>
.g-map {
  width: 100%;
  height: 400px;
  margin-bottom: 15px;
}
/* X X X X DESKTOP  X X X  */

@media (min-width: 993px) {
}
</style>
