<template>
  <div>
    <header-image></header-image>
    <div class="container col-sm-8">
      <h1 class="mt-4 mb-4">Forgot Password</h1>
      <div v-if="submitSuccess" class="success">
        <p>
          Your password reset request has been submitted successfully.<br/><br/>
          If you have an account with us you will receive an email with a link to reset your password.
        </p>
        <div class="col-sm-12 col-md-12 mt-4 text-right">
          <router-link
              to="/"
              class="btn btn-secondary mt-1">
            Return to Login
          </router-link>
        </div>
      </div>
      <div v-else>
        <p>Please enter your email address below and click the Reset Password button and we will email you a link to reset your password.</p>
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <label>Email</label>
            <input
                type="email"
                id="email"
                class="form-control"
                v-model="userEmail"
            />
          </div>

          <div class="col-sm-12 col-md-12 mt-4 text-right">
            <div class="btn btn-primary" @click="resetPassword()">Reset Password</div>
          </div>



          <div v-if="errors.length"class="errors">
            <ul>
              <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import store from "../store";
import router from "../router";

import HeaderImage from "../components/HeaderImage";

export default {
  name: "ForgotPassword",
  metaInfo: {
    title: "Forgot Password",
    // override the parent template and just use the above title only
    meta: [{ name: "description", content: "Forgot password" }],
  },
  components: {
    HeaderImage,
  },
  data: function () {
    return {
      errors: [],
      submitSuccess: false,
      userEmail: "",
      userPassword: "",
      invite: {},
      user: {
        user_id: "",
      }
    };
  },
  computed: {
  },
  mounted() {
    this.submitSuccess = false;
  },
  methods: {
    async resetPassword() {
      this.invite = this.$store.state.invite;
      this.errors = [];

      if (!this.userEmail) {
        this.errors.push("Email required.");
      } else if (!this.validEmail(this.userEmail)) {
        this.errors.push("Valid email required.");
      }

      if (!this.errors.length) {
        this.$http
          .post(
            this.$store.state.appURL + "send-reset-password",
            {
              user_email: this.userEmail,
            }
          )
          .then((response) => {
            this.submitSuccess = true;
          })
          .catch((e) => {
            this.submitSuccess = true;
            this.errors.push(
              "Unable to reset password, please try again"
            );
          });
      }
    },

    validEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>

