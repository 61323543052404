<template>
  <div>
    <header-image></header-image>
    <div class="container col-sm-8">
      <h1 class="col-sm-12 col-md-12 text-center mt-5 mb-4">
        Post Owner Setup
      </h1>

      <div class="row">
        <div class="col-12 text-justify">
          <p>Setting up your account</p>

          <label>Company Name</label>
          <input
            type="text"
            class="form-control"
            v-model="invite.company.name"
          />
          <label>First Name</label>
          <input
            type="text"
            class="form-control"
            v-model="invite.company.manager_first"
          />
          <label>Last Name</label>
          <input
            type="text"
            class="form-control"
            v-model="invite.company.manager_last"
          />
          <label>Address</label>
          <input
            type="text"
            class="form-control"
            v-model="invite.company.address_one"
          />
          <label>Town/City</label>
          <input
            type="text"
            class="form-control"
            v-model="invite.company.address_two"
          />
          <label>Post Code</label>
          <input
            type="text"
            class="form-control"
            v-model="invite.company.post_code"
          />
          <label>Country</label>
          <input
            type="text"
            class="form-control"
            v-model="invite.company.country"
          />

          <hr />
          <label
            >Company Logo File (min 100px X 100px, max 400px X 400px)</label
          >
          <input
            type="file"
            accept="image/*"
            class="form-control"
            @change="processFile($event)"
          />

          <label
            >Price (ex. VAT) £{{
              workplace_price
            }}/kWh</label
          >
          <input type="text" class="form-control" v-model="workplace_price" />

          <div class="col-sm-12 col-md-12 mt-4 mb-5 text-center">
            <div class="btn btn-primary" @click="processCompany()">
              <span v-if="invite.company.id">Update Company</span>
              <span v-else>Add Company</span>
            </div>
          </div>
          <div v-if="successMsg" class="col-sm-12 success-msg mt-3">
            {{ successMsg }}
            <font-awesome-icon
              class="mt-1 fa-lg float-right"
              icon="check-circle"
            />
          </div>
          <div
            v-if="successMsg"
            class="col-sm-12 col-md-12 mt-4 mb-5 text-center"
          >
            <router-link to="/add-invites" class="btn btn-primary"
              >Add Invites</router-link
            >
          </div>
          <!-- error section -->
          <div class="errors">
            <p v-if="errors.length">
              <em>Please correct the following error(s):</em>
            </p>

            <ul>
              <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <footer-menu></footer-menu>
  </div>
</template>

<script>
import store from "../../store";
import router from "../../router";
import { mapState, mapGetters, mapMutations } from "vuex";

import HeaderImage from "../../components/HeaderImage";
import Journey from "../../components/Journey.vue";
import FooterMenu from "../../components/FooterMenu";

export default {
  name: "CompanyDetails",
  metaInfo: {
    title: "Company Details",
    // override the parent template and just use the above title only
    meta: [{ name: "description", content: "Company Details page" }],
  },
  components: {
    HeaderImage,
    Journey,
    FooterMenu,
  },
  data: function () {
    return {
      errors: [],
      successMsg: "",
      workplace_price: "",
      logo: null,

      invite: {
        company: {
          owner_id: null,
          name: "",
          manager_first: "",
          manager_last: "",
          address_one: "",
          address_two: "",
          post_code: "",
          country: "",
          updated_at: "",
          created_at: "",
          id: "",
          price: "",
        },
      },
      revenue_rates: {
        owner_id: null,
        revenue_rate_energy_charge: "",
        revenue_rate_plan: 21,
        revenue_rate_charge: 2,
        revenue_rate_cost: 0,
        revenue_rate_credit_per_hour: 0,
        revenue_rate_parking_charge: 0,
      },
    };
  },
  mounted() {
    this.invite = this.$store.state.invite;

    // only do the prefill if you have a company_id

    this.invite.company.owner_id = this.invite.owner_id;
    this.revenue_rates.owner_id = this.invite.owner_id;
    if (this.invite.company.id) {
      this.$http
          .get(this.$store.state.appURL + "company/" + this.invite.company.id,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  "Authorization": this.$store.state.api_token,
                }
          })
        .then((response) => {
          this.invite.company = response.data;

          this.$http
            .get(
              this.$store.state.appURL +
                "revenue-show?owner_id=" +
                this.revenue_rates.owner_id +
                "&revenue_rate_plan=" +
                this.revenue_rates.revenue_rate_plan,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": this.$store.state.api_token,
                  }
            })
            .then((response) => {
              this.revenue_rates = response.data;
              this.workplace_price = Number(
                this.revenue_rates.revenue_rate_energy_charge
              ).toFixed(2);
            })
            .catch((error) => {
              for (let [key, value] of Object.entries(error.response.data)) {
                this.errors.push(value[0]);
              }
            });
        })
        .catch((error) => {
          for (let [key, value] of Object.entries(error.response.data)) {
            this.errors.push(value[0]);
          }
        });
    }
  },
  methods: {
    processFile(event) {
      this.logo = event.target.files[0];
    },
    processCompany() {
      //add company details
      this.errors = [];
      this.successMsg = "";
      this.revenue_rates.revenue_rate_energy_charge = Number(
        this.workplace_price
      ).toFixed(2);

      let formData = new FormData();
      if (this.logo) {
        formData.append("logo", this.logo);
      }

      for (var key in this.invite.company) {
        formData.append(key, this.invite.company[key]);
      }
      
      if (this.invite.company.id) {
        //Update
        this.$http
          .post(
            this.$store.state.appURL + "company/" + this.invite.company.id,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": this.$store.state.api_token,
              },
            }
          )
          .then((response) => {
            this.successMsg = "Company updated successfully";
            //this.invite.company = response.data;
            this.invite.company_id = this.invite.company.id;

            store.commit("updateInvite", this.invite);

            this.$http
              .post(
                this.$store.state.appURL + "revenue-update",
                this.revenue_rates, {
                    headers: {
                     "Authorization": this.$store.state.api_token,
                    },
                  })
              .then((response) => {})
              .catch((error) => {
                for (let [key, value] of Object.entries(error.response.data)) {
                  this.errors.push(value[0]);
                }
              });
          })
          .catch((error) => {
            for (let [key, value] of Object.entries(error.response.data)) {
              this.errors.push(value[0]);
            }
          });
      } else {
        //Add
        this.$http
          .post(this.$store.state.appURL + "company", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
               "Authorization": this.$store.state.api_token,
            },
          })
          .then((response) => {
            this.successMsg = "Company added successfully";
            this.invite.company = response.data;

            this.invite.company_id = this.invite.company.id;
            store.commit("updateInvite", this.invite);

            this.$http
              .post(this.$store.state.appURL + "revenue", this.revenue_rates,{
                  headers: {
                    "Authorization": this.$store.state.api_token,
                  },
                })

              .then((response) => {})
              .catch((error) => {
                for (let [key, value] of Object.entries(error.response.data)) {
                  this.errors.push(value[0]);
                }
              });
          })
          .catch((error) => {
            for (let [key, value] of Object.entries(error.response.data)) {
              this.errors.push(value[0]);
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.errors {
  bottom: 30px;
}
.form-control {
  margin-bottom: 15px;
}
</style>
