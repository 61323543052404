<template>
    <div class="header-img">
      <div class="logo-frame">
        <div v-if="logo" class="company-logo">
          <img :src="logo" />
          
        </div>  
        <div v-if="success" class="success"><font-awesome-icon icon="check-circle" /></div>
      </div>
    </div>
</template>
<script>
export default {
  data: function() {
    return {};
  },
  props: {
      logo: String,
      success: Boolean
  }
};
</script>
<style scoped>
.header-img {
    min-height: 250px;
    background-image: url("../img/polar-workplace-bg.svg");
    background-size:  cover;
    background-position: 45%;
    background-repeat: no-repeat;
}
.logo-frame {
  width: auto;
}
.company-logo {
    position: relative;
    background-color: #fff;
    top: 70px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;
    border: #fff 2px solid;
    box-shadow: #333 0px 1px 2px;
}
.company-logo img {
    max-width: 80%;
    height: auto;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.success {
  position: relative;
  color: #26ACE2;
  top: 45px;
  left: 45px;
  width: 30px;
  font-size: 2.2em;
  margin: 0 auto;
}
/* X X X X DESKTOP  X X X  */

@media (min-width: 993px) {
  .header-img {
    min-height: 350px;
  }
  .company-logo {
    top: 135px;
  }
  .success {
    top: 100px;
  }
}
</style>
