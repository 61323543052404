import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import LogIn from "../views/LogIn.vue";
import VerifyUser from "../views/verify-polar/VerifyUser.vue";
import Invite from "../views/onboard/Invite.vue";
import YourDetails from "../views/onboard/YourDetails.vue";
import GetCard from "../views/onboard/GetCard.vue";
import Payment from "../views/onboard/Payment.vue";
import Success from "../views/onboard/Success.vue";
import PaymentFailed from "../views/onboard/PaymentFailed.vue";
import CompanyDetails from "../views/post-owner/CompanyDetails.vue";
import AddInvites from "../views/post-owner/AddInvites.vue";
import About from "../views/About.vue";
import Terms from "../views/Terms.vue";
import ActivateCard from "../views/ActivateCard.vue";
import Pricing from "../views/Pricing.vue";
import Contact from "../views/Contact.vue";
import Help from "../views/Help.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "login",
    component: LogIn
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPassword,
    beforeEnter(to, from, next) {
      if (to.query.token) {
        next()
      } else {
        next({
          name: "login"
        });
      }
    }
  },
  {
    path: '/company-details',
    name: 'company-details',
    component: CompanyDetails,
    beforeEnter(to, from, next) {
      // check vuex store //
      if (store.state.authenticated) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }
  },
  {
    path: '/add-invites',
    name: 'addinvites',
    component: AddInvites,
    beforeEnter(to, from, next) {
      // check vuex store //
      if (store.state.authenticated) {
        next()
      } else {
        next({
          name: "login" // back to safety route //
        });
      }
    }
  },
  {
    path: '/verify-polar/verify-user',
    name: 'verifyuser',
    component: VerifyUser
  },
  {
    path: '/invite',
    name: 'invite',
    component: Invite
  },
  {
    path: '/your-details',
    name: 'yourdetails',
    component: YourDetails
  },
  {
    path: '/get-card',
    name: 'getcard',
    component: GetCard
  },
  {
    path: '/payment',
    name: 'payment',
    component: Payment
  },
  {
    path: '/success',
    name: 'success',
    component: Success
  },
  {
    path: '/payment-failed',
    name: 'paymentfailed',
    component: PaymentFailed
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms
  },
  {
    path: '/activate-card',
    name: 'activatecard',
    component: ActivateCard
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: Pricing
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/help',
    name: 'help',
    component: Help
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
