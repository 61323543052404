<template>
  <div>
    <header-image></header-image>
    <div class="container col-sm-8">
      <h1 class="col-sm-12 col-md-12 text-center mt-5 mb-4">Add Invites</h1>

      <div class="row">
        <div class="col-12 text-justify">
          <p>Add invites to your EV charging scheme</p>

          <label>First Name</label>
          <input type="text" class="form-control" v-model="invite.first_name"/>
          <label>Last Name</label>
          <input type="text" class="form-control" v-model="invite.last_name"/>
          <label>Email</label>
          <input type="text" class="form-control" v-model="invite.email"/>

          <div v-if="successMsg" class="col-sm-12 success-msg mt-3">
            {{ successMsg }}
            <font-awesome-icon
                class="mt-1 fa-lg float-right"
                icon="check-circle"
            />
          </div>
          <div class="col-sm-12 col-md-12 mt-4 mb-5 text-center">
            <div class="btn btn-primary" @click="addInvites()">Add Invite</div>
          </div>
          <!-- error section -->
          <div class="errors">
            <p v-if="errors.length">
              <em>Please correct the following error(s):</em>
            </p>
            <ul>
              <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
          </div>
          <hr/>
          <h1 class="col-sm-12 col-md-12 text-center mt-5 mb-4">
            Import Invites from CSV file
          </h1>
          <div class="row mt-3">
            <div class="col-sm-6">
              <label>Invites Import File</label>
              <div class="drop-zone">
                <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    :options="dropzoneOptions"
                    @vdropzone-sending="sendingEvent"
                    @vdropzone-file-added="processFile"
                    @vdropzone-complete="uploadComplete"
                ></vue-dropzone>
                <div v-if="fileAdded" class="delete-file" @click="removeFile()">
                  X
                </div>
              </div>
            </div>

            <div class="col-sm-6 mt-4 text-right">
              <div class="btn btn-primary" @click="submitFile()">Enter</div>
            </div>
          </div>

          <div v-if="successMsgImp" class="col-sm-12 success-msg mt-3">
            {{ successMsgImp }}
            <font-awesome-icon
                class="mt-1 fa-lg float-right"
                icon="check-circle"
            />
          </div>
          <!-- error section -->
          <div class="errors mt-5">
            <p v-if="import_errors.length">
              <em>Please correct the following error(s):</em>
            </p>
            <ul>
              <li v-for="(error, index) in import_errors" :key="index">
                {{ error }}
              </li>
            </ul>

            <p v-if="validationErrors.length">
              <em>The CSV file has errors and was not imported:</em>
            </p>
            <ul>
              <li
                  v-for="(validationError, index) in validationErrors"
                  :key="index"
              >
                {{ validationError }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <footer-menu></footer-menu>
  </div>
</template>

<script>
import store from "../../store";
import router from "../../router";
import {mapState, mapGetters, mapMutations} from "vuex";

import HeaderImage from "../../components/HeaderImage";
import Journey from "../../components/Journey.vue";
import FooterMenu from "../../components/FooterMenu";

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "AddInvites",
  metaInfo: {
    title: "Add Invites",
    // override the parent template and just use the above title only
    meta: [{name: "description", content: "Add Invites page"}],
  },
  components: {
    HeaderImage,
    Journey,
    FooterMenu,
    vueDropzone: vue2Dropzone,
  },
  data: function () {
    return {
      errors: [],
      import_errors: [],
      successMsg: "",
      successMsgImp: "",
      addingInvites: false,

      invite: {
        first_name: "",
        last_name: "",
        company_id: "", // Company id must exist
        email: "",
      },

      dropzoneOptions: {
        url: this.$store.state.appURL + "invite/import",
        paramName: "csv_file",
        autoProcessQueue: false,
        acceptedFiles: ".csv",
        headers: {
          "Authorization": this.$store.state.api_token,
        }
      },
      file: "",
      validationErrors: [],
      formData: {},
      fileAdded: false,
      validCSV: true,
      offerData: [],
    };
  },
  mounted() {
    this.invite = store.state.invite;
  },
  methods: {
    addInvites() {
      //validate  user details
      this.errors = [];
      this.successMsg = "";
      // if an async request is processing
      if (this.processing === true) {
        return;
      } else {
        this.addingInvites = true;
      }
      this.invite.company_id = this.invite.company.id;
      this.$http
          .post(this.$store.state.appURL + "invite", this.invite, {
            headers: {
              "Authorization": this.$store.state.api_token,
            }
          })
          .then((response) => {
            this.successMsg = "Invite successfully added - add another?";
            this.invite.code = response.data.code;
            // clear fields
            this.addingInvites = false;
            this.invite.first_name = "";
            this.invite.last_name = "";
            this.invite.email = "";
          })
          .catch((error) => {
            for (let [key, value] of Object.entries(error.response.data)) {
              this.errors.push(value[0]);
            }
          });
    },

    processFile() {
      this.fileAdded = true;
    },

    removeFile() {
      this.$refs.myVueDropzone.removeAllFiles();
      this.fileAdded = false;
    },

    sendingEvent(file, xhr, formData) {
      formData.append("company_id", this.invite.company.id);
    },

    submitFile() {
      this.import_errors = [];
      this.validationErrors = [];
      this.successMsgImp = "";

      if (!this.fileAdded) {
        this.import_errors.push("Invites Import CSV file required.");
      }

      if (!this.import_errors.length) {
        this.$refs.myVueDropzone.processQueue();
      }
    },

    uploadComplete(response) {
      // console.log(response.xhr);
      switch (response.xhr.status) {
        case 200:
          this.successMsgImp = "Uploaded successfully";
          break;
        case 422:
          const json = JSON.parse(response.xhr.response);
          this.parseErrors(json);
          break;
        default:
          this.import_errors.push("Server error");
      }
      this.$refs.myVueDropzone.removeAllFiles();
      this.fileAdded = false;
    },
    parseErrors(errors) {
      if (Array.isArray(errors)) {
        errors.forEach((element) => {
          this.validationErrors.push(element[0]);
        });
      } else if (typeof errors == "object") {
        for (let [key, value] of Object.entries(errors)) {
          this.validationErrors.push(value[0]);
        }
      }
    },
  },
};
</script>
<style scoped>
.errors {
  bottom: 30px;
}
</style>
