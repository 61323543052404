import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {TileSpinner} from 'vue-spinners'
import axios from 'axios'
import { BSpinner } from 'bootstrap-vue'

Vue.component('b-spinner', BSpinner)
Vue.component('tile', TileSpinner)
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/global.css";

import { library } from '@fortawesome/fontawesome-svg-core'
// SOLID
import {
  faCheckCircle,
  faTimesCircle,
  faCheck,
  faCircle as fasFaCircle,
  faDotCircle as fasFaDotCircle,
  faCreditCard,
  faBuilding,
  faHome,
  faPlug,
  faCircle,
  faQuestion,
  faTag,
  faBolt,
  faPlus,
  faCalendar,
  faTags,
  faMapMarkerAlt,
  faPoundSign,
  faUser,
  faHistory,
  faAsterisk,
  faChargingStation

} from '@fortawesome/free-solid-svg-icons'
// REGULAR
import {
  faCircle as farFaCircle,
  faDotCircle as farFaDotCircle

} from '@fortawesome/free-regular-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(
  faCheckCircle,
  faTimesCircle,
  faCheck,
  fasFaDotCircle,farFaDotCircle,
  fasFaCircle,farFaCircle,
  faCreditCard,
  faBuilding,
  faHome,
  faPlug,
  faCircle,
  faQuestion,
  faTag,
  faBolt,
  faPlus,
  faCalendar,
  faTags,
  faMapMarkerAlt,
  faPoundSign,
  faUser,
  faHistory,
  faAsterisk,
  faChargingStation

)


Vue.prototype.$http = axios

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDUg52ZGftsuw8kx_stbSJsLWPwFN61r3c',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
