<template>
  <div>
    <header-image :logo="logoURL"></header-image>
    <div class="container col-sm-8">
      <h1 class="text-center mt-5 mb-4">
        bp pulse Workplace at
        <strong>{{ invite.company.name }}</strong>
      </h1>
      <div class="row">
        <div class="col-2">
          <journey :next-step="3"></journey>
        </div>
        <!-- WHat RFID -->
        <div class="col-10 text-justify">
          <p>
            Here at bp Chargemaster we use direct deposit through
            <a href="https://gocardless.com/">Go Cardless</a>; this direct
            deposit makes your life easier so you don't have to manage anything
            and it's all taken care of. After signup, you will receive an email
            from Go Cardless for notification of payment.
          </p>
          <p>
            Don't worry - you will always have the opportunity to cancel your
            direct deposit if you choose to. More information
            <a
              href="https://support.gocardless.com/hc/en-gb/articles/115002835269-FAQs-for-customers-paying-through-GoCardless"
              >here</a
            >
          </p>
          <div class="row justify-content-md-center">
            <div
              v-if="invite.meta_data.workplace_only"
              class="card deal col-sm-12 col-md-12 col-lg-5"
            >
              <workplace-deal :work-price="workplace_price"></workplace-deal>
            </div>
            <div
              v-if="!invite.meta_data.workplace_only"
              class="card deal col-sm-12 col-md-12 col-lg-5"
            >
              <workplace-polar-deal
                :work-price="workplace_price"
                :polar-price="this.$store.state.company_polar_price"
              ></workplace-polar-deal>
            </div>

            <div class="col-sm-12 col-md-12 mt-4 mb-5 text-center">
              <div @click="payment()" class="btn btn-primary">Next</div>
            </div>
            <div class="col-sm-12 col-md-12 mt-4 mb-5 text-center">
              <p>
                <router-link to="/terms" class
                  >Terms and Conditions</router-link
                >
              </p>
            </div>
          </div>

          <!-- error section -->
          <div class="errors">
            <p v-if="errors.length">
              <em>Please correct the following error(s):</em>
            </p>
            <ul>
              <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <footer-menu></footer-menu>
  </div>
</template>

<script>
import store from "../../store";
import router from "../../router";
import { mapState, mapGetters, mapMutations } from "vuex";

import HeaderImage from "../../components/HeaderImage";
import Journey from "../../components/Journey.vue";
import WorkplaceDeal from "../../components/WorkplaceDeal";
import WorkplacePolarDeal from "../../components/WorkplacePolarDeal";
import FooterMenu from "../../components/FooterMenu";

export default {
  name: "Payment",
  metaInfo: {
    title: "Your Payment",
    // override the parent template and just use the above title only
    meta: [{ name: "description", content: "Payment page" }],
  },
  components: {
    HeaderImage,
    Journey,
    WorkplaceDeal,
    WorkplacePolarDeal,
    FooterMenu,
  },
  data: function () {
    return {
      errors: [],
      successMsg: "",
      logoURL: "",
      workplace_price: "",

      invite: {
        id: "",
        company_id: "",
        code: "",
        first_name: "",
        last_name: "",
        email: "",
        meta_data: {},
        created_at: "",
        updated_at: "",
        company: {
          id: "",
          logo: null,
          name: "",
          manager_first: "",
          manager_last: "",
          address_one: "",
          address_two: "",
          post_code: "",
          country: "",
          created_at: "",
          updated_at: "",
        },
      },
      your_details: {
        user_email: "",
        user_password: "",
        user_firstname: "",
        user_lastname: "",
        user_vehicle: "",
        vehicle_id: "",
        workplace_only: null,
      },
      user: {
        first_name: "",
        last_name: "",
        address_one: "",
        address_two: "",
        post_code: "",
        country: "",
        email: "",
        company_id: "",
        rfid_type_fob: null,
        workplace_only: null,
        deliver_workplace: null,
        verified_polar_member: null,
      },
      flow: {
        user_id: "",
        description: "workplace",
      },
      revenue_rates: {
        owner_id: "",
        revenue_rate_energy_charge: "",
        revenue_rate_plan: 21,
      },
    };
  },
  mounted() {
    this.invite.code = this.$route.query.code;
    // get screen data using code
    this.$http
      .get(this.$store.state.appURL + "invite/" + this.invite.code)
      .then((response) => {
        this.invite = response.data;

        this.your_details.workplace_only = this.invite.meta_data.workplace_only;
        this.your_details.user_firstname = this.invite.first_name;
        this.your_details.user_lastname = this.invite.last_name;
        this.your_details.user_email = this.invite.email;
        this.flow.user_id = this.invite.meta_data.user_id;

        if (this.invite.company.logo) {
          this.logoURL = this.$store.state.appURL + this.invite.company.logo;
        }

        this.$http
          .get(
            this.$store.state.appURL +
              "company/" +
              this.invite.company.id +
              "?code=" +
              this.invite.code,
            {
              headers: {
              "Authorization": this.$store.state.api_token,
            }
          })
          .then((response) => {
            this.invite.company = response.data;

            this.revenue_rates.owner_id = this.invite.company.owner_id;

            this.$http
              .get(
                this.$store.state.appURL +
                  "revenue-show?owner_id=" +
                  this.revenue_rates.owner_id +
                  "&revenue_rate_plan=" +
                  this.revenue_rates.revenue_rate_plan
              )
              .then((response) => {
                this.revenue_rates = response.data;
                this.workplace_price = Number(
                  this.revenue_rates.revenue_rate_energy_charge
                ).toFixed(2);

                this.$http
                  .get(
                    this.$store.state.appURL +
                      "rfid-delivery/" +
                      this.invite.meta_data.rfid_key,
                      {
                        headers: {
                          "Authorization": this.$store.state.api_token,
                        }
                      }
                  )
                  .then((response) => {
                    this.user = response.data;
                    this.user.first_name = this.invite.first_name;
                    this.user.last_name = this.invite.last_name;

                    this.$http
                      .get(
                        this.$store.state.appURL +
                          "user/" +
                          this.invite.meta_data.user_id,
                          {
                            headers: {
                              "Authorization": this.$store.state.api_token,
                            },
                          })
                      .then((response) => {
                        this.flow.user_id = this.invite.meta_data.user_id;
                        this.your_details.user_password =
                          response.data.user_vehicle;
                        this.your_details.user_vehicle =
                          response.data.user_vehicle;
                        this.your_details.vehicle_id = response.data.vehicle_id;
                      })
                      .catch((error) => {
                        for (let [key, value] of Object.entries(
                          error.response.data
                        )) {
                          this.errors.push(value[0]);
                        }
                      });
                  })
                  .catch((error) => {
                    for (let [key, value] of Object.entries(
                      error.response.data
                    )) {
                      this.errors.push(value[0]);
                    }
                  });
              })
              .catch((error) => {
                for (let [key, value] of Object.entries(error.response.data)) {
                  this.errors.push(value[0]);
                }
              });
          })
          .catch((error) => {
            for (let [key, value] of Object.entries(error.response.data)) {
              this.errors.push(value[0]);
            }
          });
      })
      .catch((error) => {
        for (let [key, value] of Object.entries(error.response.data)) {
          this.errors.push(value[0]);
        }
      });
  },
  methods: {
    payment() {
      // set up records from store
      //this.your_details = store.state.your_details;
      //this.your_details.workplace_only = this.invite.meta_data.workplace_only;
      //this.user = store.state.user;

      // call api's to write data
      /* axios
        .patch(
          this.$store.state.appURL + "invite/" + this.invite.code,
          this.invite
        )
        .then(response => {
          axios
            .post(this.$store.state.appURL + "user", this.your_details)
            .then(response => {

              this.flow.user_id = response.data.id;

              axios
                .post(this.$store.state.appURL + "rfid-delivery", this.user)
                .then(response => {*/
      this.$http
        .post(this.$store.state.appURL + "gc/redirect-flow", this.flow,
            {
              headers: {
                "Authorization": this.$store.state.api_token,
              }
            })
        .then((response) => {
          window.location.href = response.data;
        })
        .catch((error) => {
          for (let [key, value] of Object.entries(error.response.data)) {
            this.errors.push(value[0]);
          }
        });
      /*})
                .catch(error => {
                  for (let [key, value] of Object.entries(
                    error.response.data
                  )) {
                    this.errors.push(value[0]);
                  }
                });
            })
            .catch(error => {
              for (let [key, value] of Object.entries(error.response.data)) {
                this.errors.push(value[0]);
              }
            });
          //getCard
        })
        .catch(error => {
          for (let [key, value] of Object.entries(error.response.data)) {
            this.errors.push(value[0]);
          }
        });*/
    },
  },
};
</script>

